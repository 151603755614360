import React from 'react'
import UpdateMcqQuestion from '../components/admin/updateMcq/UpdateMcqQuestion'

const UpdateMcqPage = () => {
  return (
    <div>
      <UpdateMcqQuestion />
    </div>
  )
}

export default UpdateMcqPage