import PropTypes from "prop-types";
import "./our-service.css";

const OurService = ({
  className = "",
  serviceCardPara = "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse varius enim in eros elementum tristique. Duis cursus, mi quis viverra ornare, eros dolor interdum nulla, ut commodo diam libero vitae erat.",
  serviceCardHeading = "Partner",
}) => {
  return (
    <div className={`our-service ${className}`}>
      <div className="section-title">
        <div className="icon-relume">
          <div className="wrapper">
            <b className="b">1</b>
          </div>
        </div>
        <div className="content2">
          <div className="content-inner">
            <div className="ellipse-parent">
              <div className="frame-child638" />
              <div className="frame-child639" />
              <div className="fab-extended">
                <img className="leading-icon" alt="" src="/leading-icon.svg" />
                <div className="add-more">Add More</div>
              </div>
              <div className="fab-extended1">
                <div className="new-item">New Item</div>
                <img
                  className="icon-chevron-right"
                  alt=""
                  src="/leading-icon2.svg"
                />
              </div>
              <img
                className="leading-icon2"
                alt=""
                src="/leading-icon1@2x.png"
              />
              <img
                className="leading-icon3"
                alt=""
                src="/leading-icon3@2x.png"
              />
              <div className="chart-line">
                <div className="line-parent">
                  <img className="line-icon1" alt="" src="/line.svg" />
                  <div className="indicator">
                    <div className="rectangle1" />
                    <div className="ellipse" />
                  </div>
                </div>
                <div className="label7">
                  <div className="label-wrapper">
                    <div className="label8">01 Aug</div>
                  </div>
                  <div className="label-container">
                    <div className="label9">05 Aug</div>
                  </div>
                  <div className="label-frame">
                    <div className="label8">15 Aug</div>
                  </div>
                  <div className="label9">25 Aug</div>
                  <div className="label12">30 Aug</div>
                </div>
              </div>
            </div>
          </div>
          <div className="content-child">
            <div className="frame-parent11">
              <div className="heading-parent">
                <div className="heading">{serviceCardHeading}</div>
                <div className="heading1">Partner</div>
              </div>
              <div className="text">{serviceCardPara}</div>
            </div>
          </div>
          <div className="actions2">
            <div className="button6">
              <div className="button7">Button</div>
            </div>
            <div className="button8">
              <div className="button9">More</div>
              <img
                className="icon-chevron-right"
                alt=""
                src="/icon--chevronright.svg"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

OurService.propTypes = {
  className: PropTypes.string,
  serviceCardPara: PropTypes.string,
  serviceCardHeading: PropTypes.string,
};

export default OurService;
