import React from 'react'
import CodingQuestion from '../components/codingQuestion/CodingTestQuestion'

const CodingQuestionPage = () => {
    return (
        <div>
            <CodingQuestion />
        </div>
    )
}

export default CodingQuestionPage