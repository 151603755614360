import PropTypes from "prop-types";
import "./divider.css";

const Divider = ({ className = "" }) => {
  return (
    <div className={`divider ${className}`}>
      <div className="rectangle" />
    </div>
  );
};

Divider.propTypes = {
  className: PropTypes.string,
};

export default Divider;
