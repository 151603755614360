import React, { useState, useEffect, useRef } from 'react';
import axiosInstance from '../../services/AxiosInstance';
import { useNavigate } from 'react-router-dom';




const McqTestQuestion = () => {
    // const location = useLocation();
    // const testId = location.state?.id;
    // const jobTitle = location.state?.title;
    const navigate = useNavigate();

    // Extract testId and jobTitle from the location state
    // eslint-disable-next-line
    const [testId, setTestId] = useState(localStorage.getItem('testId') || null);
    // eslint-disable-next-line
    const [jobTitle, setJobTitle] = useState(localStorage.getItem('jobTitle') || null);




    const [questions, setQuestions] = useState([]);
    const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
    const [selectedOption, setSelectedOption] = useState(null);
    const [answers, setAnswers] = useState([]);
    const [isSubmitted, setIsSubmitted] = useState(false);
    const [percentage, setPercentage] = useState(0);
    const [timer, setTimer] = useState(20 * 60); 
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState(null);
    const [submissionError, setSubmissionError] = useState(null);
    // const [warningCount, setWarningCount] = useState(0);
    const [isSubmitting, setIsSubmitting] = useState(false);

    const warningCountRef = useRef(0);



    useEffect(() => {
        const handleVisibilityChange = () => {
            if (document.visibilityState === 'hidden') {
                warningCountRef.current += 1;

                if (warningCountRef.current === 1) {
                    alert('Warning: Please do not switch tabs during the test.');
                } else if (warningCountRef.current === 2) {
                    alert('Warning: Last warning if you change the tabs again your test is not submitted & redirected to home page.');
                } else if (warningCountRef.current >= 3) {
                    alert('You have switched tabs twice. Redirecting to the homepage.');
                    navigate('/career'); // Redirect to homepage or any other route
                }
            }
        };

        document.addEventListener('visibilitychange', handleVisibilityChange);

        return () => {
            document.removeEventListener('visibilitychange', handleVisibilityChange);
        };
    }, [navigate]);

    useEffect(() => {
        // Remove testId and jobTitle from localStorage when the page is reloaded
        localStorage.removeItem('testId');
        localStorage.removeItem('jobTitle');

        // Check if testId and jobTitle are null, and navigate to the home page if true
        if (!testId || !jobTitle) {
            navigate("/career");
        }
    }, [testId, jobTitle, navigate]);

    useEffect(() => {
        if (jobTitle) {
            axiosInstance.get(`/backend/mcq/job/${jobTitle}`)
                .then((response) => {
                    const apiQuestions = response.data.map(q => ({
                        id: q.id,
                        question: q.question,
                        options: [q.optionA, q.optionB, q.optionC, q.optionD],
                        answer: q.correctAnswer
                    }));

                    // Shuffle the array to randomize the questions
                    const shuffledQuestions = apiQuestions.sort(() => 0.5 - Math.random());

                    // Select the first 20 questions
                    const selectedQuestions = shuffledQuestions.slice(0, 20);

                    setQuestions(selectedQuestions);
                    setAnswers(Array(selectedQuestions.length).fill(null));
                    setIsLoading(false);
                })
                .catch(error => {
                    console.error('Error fetching questions:', error);
                    setError('Failed to load questions. Please try again later.');
                    setIsLoading(false);
                });
        }
    }, [jobTitle]);


    useEffect(() => {
        if (timer > 0) {
            const intervalId = setInterval(() => {
                setTimer(prev => prev - 1);
            }, 1000);
            return () => clearInterval(intervalId);
        } else if (timer === 0) {
            // eslint-disable-next-line
            handleSubmit(); // Auto-submit when timer reaches 0
        }
        // eslint-disable-next-line
    }, [timer]);



    const handleOptionChange = (option) => {
        setSelectedOption(option);
    };

    const handleNext = () => {
        if (selectedOption !== null) {
            const newAnswers = [...answers];
            newAnswers[currentQuestionIndex] = selectedOption;
            setAnswers(newAnswers);

            setSelectedOption(null);
            setCurrentQuestionIndex((prev) => prev + 1);
        }
    };


    const handleSubmit = () => {
        setIsSubmitting(true); // Show loading spinner

        const newAnswers = [...answers];

        // If the current question has not been answered, set it to null
        if (selectedOption !== null) {
            newAnswers[currentQuestionIndex] = selectedOption;
        } else {
            newAnswers[currentQuestionIndex] = null;
        }

        // Ensure all remaining unanswered questions are set to null
        for (let i = currentQuestionIndex + 1; i < questions.length; i++) {
            if (newAnswers[i] === null) {
                newAnswers[i] = null;
            }
        }

        setAnswers(newAnswers);

        // Calculate the correct answers
        const correctAnswersCount = newAnswers.reduce((count, answer, index) => {
            if (answer === questions[index].answer) {
                return count + 1;
            }
            return count;
        }, 0);

        const totalQuestions = questions.length;
        const calculatedPercentage = (correctAnswersCount / totalQuestions) * 100;

        const submissionData = {
            totalMcqQuestions: totalQuestions,
            totalMcqAnswers: newAnswers.length,
            correctMcqAnswers: correctAnswersCount,
            percentageMcqAnswers: calculatedPercentage,
            answers: questions.map((q, index) => ({
                question: q.question,
                selectedOption: newAnswers[index],
                answer: q.answer
            }))
        };

        axiosInstance.post(`/backend/mcq/answers/${testId}`, submissionData)
            .then(response => {
                setPercentage(calculatedPercentage);
                setIsSubmitted(true);
                setSubmissionError(null);
            })
            .catch(error => {
                setSubmissionError('Failed to submit answers. Please try again later.');
            })
            .finally(() => {
                setIsSubmitting(false); // Hide loading spinner
            });
    };

    const formatTime = (seconds) => {
        const minutes = Math.floor(seconds / 60);
        const remainingSeconds = seconds % 60;
        return `${minutes}:${remainingSeconds < 10 ? '0' : ''}${remainingSeconds}`;
    };

    const handleNavigate = () => {
        if (jobTitle === 'java') {
            navigate("/coding-instruction", { state: { id: testId, title: jobTitle } })
        } else {
            navigate("/upload-document", { state: { id: testId, title: jobTitle } })
        }
    }

    const currentQuestion = questions[currentQuestionIndex] || {};

    return (
        <div className="max-w-3xl m-5 mx-auto p-6 bg-white shadow-xl rounded-lg border border-gray-200 row">
            {isLoading ? (
                <div className="fixed inset-0 flex items-center justify-center bg-gray-800 bg-opacity-50 z-50">
                    <div className="bg-white p-6 rounded-lg shadow-lg">
                        <button disabled type="button" className="py-2.5 px-5 me-2 text-sm font-medium text-gray-900 bg-white rounded-lg border border-gray-200 hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-2 focus:ring-blue-700 focus:text-blue-700 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700 inline-flex items-center">
                            <svg aria-hidden="true" role="status" className="inline w-4 h-4 me-3 text-gray-200 animate-spin dark:text-gray-600" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor" />
                                <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="#1C64F2" />
                            </svg>
                            Loading...
                        </button>                    </div>
                </div>
            ) : error ? (
                <div className="fixed inset-0 flex items-center justify-center bg-gray-800 bg-opacity-50 z-50">
                    <div className="bg-white p-6 rounded-lg shadow-lg">
                        <h1 className="text-2xl font-extrabold mb-4 text-gray-800">{error}</h1>
                        <button
                            onClick={() => navigate('/')}
                            className="bg-red-600 text-white py-3 px-6 rounded-lg shadow-lg hover:bg-red-700 transition duration-300 ease-in-out"
                        >
                            Go Back
                        </button>
                    </div>
                </div>
            ) : (
                !isSubmitted ? (
                    <>
                        <h1 className="text-3xl font-extrabold text-center mb-6 text-gray-800 col-md-6 col-sm-12">
                            Question {currentQuestionIndex + 1} of {questions.length}
                        </h1>
                        <div className="text-center mb-4 col-md-6 col-sm-12">
                            <p className="text-lg font-medium text-danger fw-bold">Time Remaining: {formatTime(timer)}</p>
                            {/* <p>{testId}</p> */}
                        </div>

                        <div className="mb-8">
                            <pre className="text-xl font-semibold mb-4 text-gray-700 whitespace-pre-wrap break-words " id="questionId">
                                {currentQuestion.question}
                            </pre>
                            <div className="space-y-4">
                                {currentQuestion.options?.map((option, index) => (
                                    <div key={index} className="flex items-center">
                                        <input
                                            type="radio"
                                            id={`option-${currentQuestion.id}-${index}`}
                                            name="option"
                                            value={option}
                                            checked={selectedOption === option}
                                            onChange={() => handleOptionChange(option)}
                                            className="h-5 w-5 text-blue-500 border-gray-300 focus:ring-blue-500"
                                        />
                                        <label
                                            htmlFor={`option-${currentQuestion.id}-${index}`}
                                            className="ml-3 text-lg text-gray-600 cursor-pointer"
                                        >
                                            {option}
                                        </label>
                                    </div>
                                ))}
                            </div>
                        </div>
                        <div className="flex justify-between items-center">
                            {currentQuestionIndex < questions.length - 1 && (
                                <button
                                    onClick={handleNext}
                                    className="bg-blue-600 text-white py-2 px-6 rounded-lg shadow-lg hover:bg-blue-700 transition duration-300 ease-in-out"
                                    disabled={selectedOption === null || isSubmitting}
                                >
                                    Next
                                </button>
                            )}
                            {currentQuestionIndex === questions.length - 1 && (
                                <button
                                    onClick={handleSubmit}
                                    className="bg-blue-600 text-white py-3 px-6 rounded-lg shadow-lg hover:bg-blue-700 transition duration-300 ease-in-out"
                                    disabled={isSubmitting} // Disable button while submitting
                                >
                                    {isSubmitting ? (
                                        <svg
                                            className="animate-spin h-5 w-5 mr-3"
                                            viewBox="0 0 24 24"
                                        >
                                            <circle
                                                className="opacity-25"
                                                cx="12"
                                                cy="12"
                                                r="10"
                                                stroke="currentColor"
                                                strokeWidth="4"
                                            ></circle>
                                            <path
                                                className="opacity-75"
                                                fill="currentColor"
                                                d="M4 12a8 8 0 018-8v8H4z"
                                            ></path>
                                        </svg>
                                    ) : (
                                        "Submit"
                                    )}
                                </button>

                            )}

                            {submissionError && (
                                <div className="fixed inset-0 flex items-center justify-center bg-gray-800 bg-opacity-50 z-50">
                                    <div className="bg-white p-6 rounded-lg shadow-lg">
                                        <h1 className="text-2xl font-bold text-red-600">{submissionError}</h1>
                                    </div>
                                </div>
                            )}
                        </div>
                    </>
                ) : (
                    <div className="fixed inset-0 flex items-center justify-center bg-gray-800 bg-opacity-50 z-50">
                        <div className="bg-white p-6 rounded-lg shadow-lg">
                            <h1 className="text-4xl font-extrabold mb-6 text-gray-800">Test Submitted Successfully</h1>
                            <p className="text-2xl mb-4 text-gray-700">
                                You scored <span className={percentage >= 60 ? "text-green-600" : "text-red-600"}>{percentage.toFixed(2)}%</span>.
                            </p>
                            <p className="text-xl mb-8 text-gray-600">
                                {percentage >= 60 ? "Congratulations! You have passed." : "Unfortunately, you have failed. Better luck next time!"}
                            </p>

                            <button onClick={handleNavigate}
                                className="bg-blue-600 text-white py-3 px-6 rounded-lg shadow-lg hover:bg-blue-700 transition duration-300 ease-in-out">
                                {jobTitle === "java" ? "Start Coding Test" : "Upload your Document"}
                            </button>
                        </div>
                    </div>
                )
            )}
        </div>
    );
};

export default McqTestQuestion;
