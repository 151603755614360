import React, { useState, useEffect } from 'react';
import axiosInstance from '../../../services/AxiosInstance';
import { useNavigate } from 'react-router-dom/dist';
import {isLoggedIn} from "../../../auth";



const ContactInquiries = () => {


    const navigate = useNavigate();

    useEffect(() => {
        if (!isLoggedIn()) {
            navigate("adminlogin");
        }

    }, [navigate])

  const [inquiries, setInquiries] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [successMessage, setSuccessMessage] = useState('');

  useEffect(() => {
    fetchInquiries();
  }, []);

  const fetchInquiries = async () => {
    setLoading(true);
    setError(null);
    try {
        debugger
      const response = await axiosInstance.get('/backend/getcontact-inquiries');
      setInquiries(response.data);
    } catch (err) {
      setError('Failed to fetch contact inquiries.');
    } finally {
      setLoading(false);
    }
  };

  const handleDelete = async (id) => {
    setLoading(true);
    try {
      await axiosInstance.delete(`/backend/contact-inquiries/delete/${id}`);
      setInquiries(inquiries.filter(inquiry => inquiry.id !== id));
      setSuccessMessage('Inquiry deleted successfully.');
    } catch (err) {
      setError('Failed to delete inquiry.');
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="p-4">
      <h2 className="text-2xl font-bold mb-4">Contact Inquiries</h2>

      {loading && <p>Loading...</p>}
      {error && <p className="text-red-500">{error}</p>}
      {successMessage && <p className="text-green-500">{successMessage}</p>}

      {!loading && !error && inquiries.length > 0 && (
        <div className="overflow-auto rounded-lg" style={{ maxHeight: '500px' }}>
          <table className="min-w-full bg-white border border-gray-300 rounded-lg shadow-md">
            <thead>
              <tr>
                <th className="px-4 py-2 border-b">#</th>
                <th className="px-4 py-2 border-b">Name</th>
                <th className="px-4 py-2 border-b">Phone</th>
                <th className="px-4 py-2 border-b">Email</th>
                <th className="px-4 py-2 border-b">Message</th>
                <th className="px-4 py-2 border-b">Actions</th>
              </tr>
            </thead>
            <tbody>
              {inquiries.map((inquiry, index) => (
                <tr key={inquiry.id}>
                  <td className="px-4 py-2 border-b">{index + 1}</td>
                  <td className="px-4 py-2 border-b">{inquiry.contactname}</td>
                  <td className="px-4 py-2 border-b">{inquiry.contactphone}</td>
                  <td className="px-4 py-2 border-b">{inquiry.contactemail}</td>
                  <td className="px-4 py-2 border-b">
                    <pre className='whitespace-pre-wrap break-words'>{inquiry.contactmessage}</pre>
                  </td>
                  <td className="px-4 py-2 border-b">
                    <div className="flex justify-center items-center">
                      <button
                        onClick={() => handleDelete(inquiry.id)}
                        className="bg-red-500 text-white px-2 py-1 rounded"
                      >
                        <i className="fa-solid fa-trash"></i>
                      </button>
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}

      {!loading && !error && inquiries.length === 0 && <p>No contact inquiries found.</p>}
    </div>
  );
};

export default ContactInquiries;
