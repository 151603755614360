import React from 'react'
import AddMcqForm from '../components/admin/addMcq/AddMcqForm'

const AddMcqPage = () => {
    return (
        <div>
            <AddMcqForm />
        </div>
    )
}

export default AddMcqPage