import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import axiosInstance from '../../../services/AxiosInstance';
import { isLoggedIn } from '../../../auth';

const DetailsTable = () => {
    const { category } = useParams();
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [showModal, setShowModal] = useState(false);
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [deleteId, setDeleteId] = useState(null);
    const [successMessage, setSuccessMessage] = useState('');
    const [selectedCandidate, setSelectedCandidate] = useState(null);



    const navigate = useNavigate();

    useEffect(() => {
        if (!isLoggedIn()) {
            navigate("adminlogin");
        }

    }, [navigate]);






    const candidateData = {
        java: "Java Candidates",
        uiux: "UI/UX Candidates",
        dm: "Marketing Candidates",
        // reactjs: "React JS Candidates",
        // fullstack: "Fullstack Candidates",
        // reactnative: "React Native Candidates",
        // python: "Python Candidates"
    };


    const fetchData = async () => {
        setLoading(true);
        setError(null);
        try {
            const response = await axiosInstance.get(`/backend/careerapi/job/${category}`);
            setData(response.data);
            console.log(response.data);
        } catch (err) {
            setError('Failed to fetch candidate data.');
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        // Redirect if category is not one of the allowed values
        if (!["java", "uiux", "dm"].includes(category)) {
            navigate("/admin/dashboard");
        } else {
            // Fetch data only if category is valid
            fetchData();
        }
        // eslint-disable-next-line
    }, [category, navigate]);






    const handleUploadDoc = (id) => () => {
        console.log("update id", id);
        navigate("/admin/upload-documents", { state: { id: id, user: "admin" } })

    }

    const showModalDelete = (id) => () => {
        console.log("delete id", id);
        setDeleteId(id)
        setShowDeleteModal(true)
    }

    const closeDeleteModal = () => {
        setShowDeleteModal(false);
        setDeleteId(null)

    }


    const handleDelete = async () => {
        setLoading(true);
        try {
            await axiosInstance.delete(`/backend/careerapi/${deleteId}`);
            setSuccessMessage('Record deleted successfully.')
            setShowDeleteModal(false);
            setDeleteId(null);
            await fetchData();
        } catch (err) {
            setError('Failed to delete Record.');
        } finally {
            setLoading(false);
        }
    };


    const openAllCodingAnswersModal = (candidate) => () => {
        setSelectedCandidate(candidate);
        setShowModal(true);
    };

    const handleCloseModal = () => {
        setShowModal(false);
        setSelectedCandidate(null);
    };



    if (loading) return <p>Loading...</p>;
    if (error) return <p className="text-red-500">{error}</p>;

    return (
        <div className="p-6 bg-white rounded-lg shadow-md">
            <h1 className="text-3xl font-bold mb-4">{candidateData[category]}</h1>
            {error && <p className="text-red-500">{error}</p>}
            {successMessage && <p className="text-green-500">{successMessage}</p>}

            <div className="overflow-x-auto">
                <table className="min-w-full divide-y divide-gray-200">
                    <thead className="bg-gray-100">
                        <tr>
                            <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Sr. No</th>
                            <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Full Name</th>
                            <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Email</th>
                            <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Mobile Number</th>
                            <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Experience Level</th>
                            <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Available to Join</th>
                            <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Skills</th>
                            <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">total mcq</th>
                            <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Correct Answers</th>
                            <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">MCQ percentage</th>
                            <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">View Codes</th>

                            <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Resume</th>
                            <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Aadhar card</th>
                            <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">10 Certificate</th>
                            <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">12 Certificate</th>
                            <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Highest Degree</th>
                            <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Internship Letter</th>
                            <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Experience Letter</th>
                            <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Upload Documents</th>
                            <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Action</th>
                        </tr>
                    </thead>
                    <tbody className="bg-white divide-y divide-gray-200">
                        {data.map((candidate, index) => (
                            <tr key={candidate.id}>
                                <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">{index + 1}</td>
                                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{candidate.name}</td>
                                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{candidate.email}</td>
                                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{candidate.mobile}</td>
                                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{candidate.experience}</td>
                                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{candidate.availability}</td>
                                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{candidate.skills}</td>
                                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                                    {candidate.mcqResult?.totalMcqQuestions !== undefined ? candidate.mcqResult.totalMcqQuestions : 'Not Available'}
                                </td>
                                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                                    {candidate.mcqResult?.correctMcqAnswers !== undefined ? candidate.mcqResult.correctMcqAnswers : 'Not Available'}
                                </td>
                                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                                    {candidate.mcqResult?.percentageMcqAnswers !== undefined ? candidate.mcqResult.percentageMcqAnswers : 'Not Available'}
                                </td>

                                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                                    {candidate.userCodingAnswers && candidate.userCodingAnswers.length > 0 ? (
                                        <button
                                            onClick={openAllCodingAnswersModal(candidate)}
                                            className="text-blue-600 hover:text-blue-800"
                                        >
                                            <i className="fa-solid fa-eye"></i> View Code
                                        </button>
                                    ) : (
                                        'Not Code Available'
                                    )}
                                </td>

                                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                                    {candidate.document?.resume ? (
                                        <a
                                            href={`${axiosInstance.defaults.baseURL}/backend/document/${candidate.document?.resume}`}
                                            target="_blank"
                                            rel="noopener noreferrer"
                                            className="text-indigo-600 hover:text-indigo-900 flex items-center justify-center"
                                        >
                                            <i className="fa-solid fa-eye" />
                                        </a>
                                    ) : (
                                        'No document'
                                    )}
                                </td>
                                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                                    {candidate.document?.aadhar ? (
                                        <a
                                            href={`${axiosInstance.defaults.baseURL}/backend/document/${candidate.document?.aadhar}`}
                                            target="_blank"
                                            rel="noopener noreferrer"
                                            className="text-indigo-600 hover:text-indigo-900 flex items-center justify-center"
                                        >
                                            <i className="fa-solid fa-eye" />
                                        </a>
                                    ) : (
                                        'No document'
                                    )}
                                </td>
                                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                                    {candidate.document?.sscCertificate ? (
                                        <a
                                            href={`${axiosInstance.defaults.baseURL}/backend/document/${candidate.document?.sscCertificate}`}
                                            target="_blank"
                                            rel="noopener noreferrer"
                                            className="text-indigo-600 hover:text-indigo-900 flex items-center justify-center"
                                        >
                                            <i className="fa-solid fa-eye" />
                                        </a>
                                    ) : (
                                        'No document'
                                    )}
                                </td>
                                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                                    {candidate.document?.hscDiplomaCertificate ? (
                                        <a
                                            href={`${axiosInstance.defaults.baseURL}/backend/document/${candidate.document?.hscDiplomaCertificate}`}
                                            target="_blank"
                                            rel="noopener noreferrer"
                                            className="text-indigo-600 hover:text-indigo-900 flex items-center justify-center "
                                        >
                                            <i className="fa-solid fa-eye" />
                                        </a>
                                    ) : (
                                        'No document'
                                    )}
                                </td>
                                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                                    {candidate.document?.degree ? (
                                        <a
                                            href={`${axiosInstance.defaults.baseURL}/backend/document/${candidate.document?.degree}`}
                                            target="_blank"
                                            rel="noopener noreferrer"
                                            className="text-indigo-600 hover:text-indigo-900 flex items-center justify-center"
                                        >
                                            <i className="fa-solid fa-eye" />
                                        </a>
                                    ) : (
                                        'No document'
                                    )}
                                </td>
                                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                                    {candidate.document?.internship ? (
                                        <a
                                            href={`${axiosInstance.defaults.baseURL}/backend/document/${candidate.document?.internship}`}
                                            target="_blank"
                                            rel="noopener noreferrer"
                                            className="text-indigo-600 hover:text-indigo-900 flex items-center  justify-center"
                                        >
                                            <i className="fa-solid fa-eye" />
                                        </a>
                                    ) : (
                                        'No document'
                                    )}
                                </td>
                                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                                    {candidate.document?.experience ? (
                                        <a
                                            href={`${axiosInstance.defaults.baseURL}/backend/document/${candidate.document?.experience}`}
                                            target="_blank"
                                            rel="noopener noreferrer"
                                            className="text-indigo-600 hover:text-indigo-900 flex items-center  justify-center"
                                        >
                                            <i className="fa-solid fa-eye" />
                                        </a>
                                    ) : (
                                        'No document'
                                    )}
                                </td>
                                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                                    <button onClick={handleUploadDoc(candidate.id)} className="text-green-600 hover:text-green-800">upload</button>
                                </td>
                                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                                    <button onClick={showModalDelete(candidate.id)} className="text-red-600 hover:text-red-800">Delete</button>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>



            {showModal && selectedCandidate && (
                <div className="fixed inset-0 flex items-center justify-center z-50">
                    <div className="bg-white rounded-lg shadow-lg flex flex-col min-w-[400px] max-w-4xl max-h-[85vh] w-full">
                        {/* Fixed Header */}
                        <div className="bg-gray-100 p-4 border-b border-gray-200">
                            <h2 className="text-xl font-bold">Coding Answers for {selectedCandidate.name}</h2>
                        </div>

                        {/* Scrollable Content */}
                        <div className="flex-grow overflow-y-auto p-4">
                            {selectedCandidate.userCodingAnswers.length === 0 ? (
                                <p className="text-gray-500">No coding answers available.</p>
                            ) : (
                                selectedCandidate.userCodingAnswers.map((answer, index) => (
                                    <div key={index} className="mb-4">
                                        <p className="font-semibold">Question {index + 1}:</p>
                                        <pre className="bg-gray-100 p-2 rounded">{answer.question}</pre>
                                        <p className="font-semibold mt-2">Answer:</p>
                                        <pre className="bg-gray-100 p-2 rounded">{answer.userCode}</pre>
                                    </div>
                                ))
                            )}
                        </div>

                        {/* Fixed Footer */}
                        <div className="bg-gray-100 p-4 border-t border-gray-200">
                            <button onClick={handleCloseModal} className="w-full px-4 py-2 bg-blue-600 text-white rounded">
                                Close
                            </button>
                        </div>
                    </div>
                </div>
            )}




            {showDeleteModal && (
                <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center">
                    <div className="bg-white p-6 rounded-lg shadow-lg">
                        <h3 className="text-lg font-bold mb-4">Confirm Deletion</h3>
                        <p>Are you sure you want to delete this MCQ?</p>
                        <div className="mt-4 flex justify-end">
                            <button
                                onClick={closeDeleteModal}
                                className="bg-green-500 text-white px-4 py-2 rounded mr-2"
                            >
                                Cancel
                            </button>
                            <button
                                onClick={handleDelete}
                                className="bg-red-500 text-white px-4 py-2 rounded"
                            >
                                Delete
                            </button>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

export default DetailsTable;
