import React from 'react'
import UploadDocument from '../components/uploadDocument/UploadDocument'

const UploadDocumentPage = () => {
    return (
        <div>
            <UploadDocument />
        </div>
    )
}

export default UploadDocumentPage