import React from 'react'
import StartTest from '../components/startTest/StartTest'

const StartTestpage = () => {
    return (
        <div>
            <StartTest />
        </div>
    )
}

export default StartTestpage