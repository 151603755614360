import React from "react";
import PropTypes from "prop-types";
import "./maps-full-map.css";

const MapsFullMap = ({ className = "" }) => {
  return (
    <div className={`maps-full-map ${className}`}>
      <iframe
        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3783.7041424917417!2d73.79851167525832!3d18.62984348714166!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bc2b9979e39b48f%3A0x88d8e7b0890b4e99!2sP.%20J.%20Chambers%2C%20304%2F2%2C%20Nehru%20Nagar%20Rd%2C%20Atma%20Nagar%2C%20Kharalwadi%2C%20Pimpri%20Colony%2C%20Pune%2C%20Pimpri-Chinchwad%2C%20Maharashtra%20411018!5e0!3m2!1sen!2sin!4v1692270802727!5m2!1sen!2sin"
        width="500"
        height="300"
        style={{ border: 0 }}
        allowFullScreen=""
        loading="lazy"
        referrerPolicy="no-referrer-when-downgrade"
        title="Google Map"
      ></iframe>
      {/* <div className="footer-info">
        <img
          className="controls-google-logo-color"
          alt=""
          src="/controls--google-logo--color.svg"
        />
        <div className="controls-google-maps-byline">
          <div className="items">
            <div className="background" />
            <div className="map-data-2019">Map data @2019 Google</div>
          </div>
          <div className="items1">
            <div className="background" />
            <div className="terms-of-use">Terms of Use</div>
          </div>
          <div className="items2">
            <div className="background" />
            <div className="report-a-map">Report a map error</div>
          </div>
        </div>
      </div> */}
    </div>
  );
};

MapsFullMap.propTypes = {
  className: PropTypes.string,
};

export default MapsFullMap;
