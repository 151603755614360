import React, { useEffect } from 'react';
import javaImage from "../../../assets/images/java.jpg";
import UIUXImage from "../../../assets/images/uiux.avif";
import marketingImage from "../../../assets/images/digital marketing.avif";
import { Link, Outlet, useNavigate } from 'react-router-dom';
import {isLoggedIn} from "../../../auth";

const Dashboard = () => {


    const navigate = useNavigate();

    useEffect(() => {
        if (!isLoggedIn()) {
            navigate("/adminlogin");
        }

    }, [navigate])

   

    const cards = [
        { id: 1, title: 'Java candidates', image: javaImage, link: 'java' },
        { id: 2, title: 'UI/UX candidates', image: UIUXImage, link: 'uiux' },
        { id: 3, title: 'Marketing candidates', image: marketingImage, link: 'dm' },
        // { id: 4, title: 'React js candidates', image: reactImage, link: 'reactjs' },
        // { id: 5, title: 'Fullstack candidates', image: fullstackImage, link: 'fullstack' },
        // { id: 6, title: 'React Native candidates', image: reactNativeImage, link: 'reactnative' },
        // { id: 7, title: 'Python candidates', image: pyhhonImage, link: 'python' },
    ];

    return (
        <div>
            <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-4 gap-4 p-4">
                {cards.map(card => (
                    <Link 
                        to={`/admin/dashboard/${encodeURIComponent(card.link)}`} 
                        key={card.id} 
                        className="block border border-gray-200 rounded-lg shadow-lg overflow-hidden hover:shadow-xl transition-shadow duration-300"
                    >
                        <div className="p-4 flex justify-between items-center">
                            <h2 className="text-lg font-semibold text-gray-700">{card.title}</h2>
                            <i className="fa-solid fa-arrow-right"></i>
                        </div>
                        <img src={card.image} alt={card.title} className="w-full h-36 object-cover" />
                    </Link>
                ))}
            </div>
            <div className="p-4">
                <Outlet />
            </div>
        </div>
    );
};

export default Dashboard;
