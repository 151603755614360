import React from 'react'
import ShowCodingQuestions from '../components/admin/showCodingQuestion/ShowCodingQuestions'

const ShowCodingQuestionPage = () => {
    return (
        <div>
            <ShowCodingQuestions />
        </div>
    )
}

export default ShowCodingQuestionPage