import React, { useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import axiosInstance from '../../services/AxiosInstance';

const Login = () => {


    const location = useLocation();
    const msg = location.state?.msg;

    const navigate = useNavigate();
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [error, setError] = useState('');
    const [success, setSuccess] = useState('');
    const [loading, setLoading] = useState(false); // Loading state

    const handleSubmit = async (e) => {
        e.preventDefault();
        setError('');
        setSuccess('');
        setLoading(true); // Set loading to true when request starts

        try {
            const response = await axiosInstance.post('/backend/auth/login', { email, password });
            if (response.data.jwtToken) {

                console.log(response.data);
                

                // this is method to store token in localstorage
                // doLogin(response.data.jwtToken);

                localStorage.setItem('token', response.data.jwtToken);
                setSuccess('Login successful! Redirecting...');

                setTimeout(() => navigate('/admin/dashboard'), 2000); // Redirect after 2 seconds

            }

        } catch (error) {
            setError(Response.data || 'Login failed. Credentials Invalid.');
        } finally {
            setLoading(false); // Set loading to false when request completes
        }

        setEmail("");
        setPassword("");
    };

    return (
        <div className="pt-5 flex items-center justify-center bg-gray-100">
            <div className="max-w-sm w-full bg-white shadow-lg rounded-lg p-6 border border-gray-200">
                <h1 className="text-3xl font-bold text-center mb-6 text-gray-800">Login</h1>
                <form onSubmit={handleSubmit}>
                    {msg && <p className=" text-green-600 text-center">{msg}</p>}
                    <div className="mb-4">
                        <label htmlFor="email" className="block text-gray-700 text-sm font-medium mb-2">Email</label>
                        <input
                            type="email"
                            id="email"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            className="w-full px-4 py-2 border border-gray-300 rounded-lg shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500"
                            placeholder="Enter your email"
                            required
                        />
                    </div>
                    <div className="mb-4">
                        <label htmlFor="password" className="block text-gray-700 text-sm font-medium mb-2">Password</label>
                        <input
                            type="password"
                            id="password"
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                            className="w-full px-4 py-2 border border-gray-300 rounded-lg shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500"
                            placeholder="Enter your password"
                            required
                        />
                    </div>
                    {error && <p className="text-red-500 text-center mb-4">{error}</p>}
                    {success && <p className="text-green-500 text-center mb-4">{success}</p>}
                    <div className="flex items-center justify-between mb-6">
                        <Link to="/forgot-password" className="text-blue-600 hover:underline text-sm">Forgot Password?</Link>
                    </div>
                    <button
                        type="submit"
                        className={`w-full bg-blue-600 text-white py-3 px-4 rounded-lg shadow-lg transition duration-300 ease-in-out ${loading ? 'opacity-50 cursor-not-allowed' : 'hover:bg-blue-700'
                            }`}
                        disabled={loading} // Disable button while loading
                    >
                        {loading ? 'Logging in...' : 'Login'}
                    </button>
                    <p className="text-center mt-4 text-gray-600 text-sm">
                        Don't have an account?{' '}
                        <Link to="/register" className="text-blue-600 hover:underline">Register</Link>
                    </p>
                </form>
            </div>
        </div>
    );
};

export default Login;
