import React from 'react'
import UpdatecodingQuestion from '../components/admin/updatecodingQuestion/UpdtaeCodingQuestion'

const UpdateCodingQuestionPage = () => {
  return (
    <div>
      <UpdatecodingQuestion />
    </div>
  )
}

export default UpdateCodingQuestionPage