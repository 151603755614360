import React, { useRef, useEffect } from "react";
import Column from "./column";
import PropTypes from "prop-types";
import "./ourprocess.css";

const Ourprocess = ({ className = "" }) => {
  const columnsRef = useRef(null);
  const isDownRef = useRef(false);
  const startXRef = useRef(0);
  const scrollLeftRef = useRef(0);
  const columnWidthRef = useRef(0);

  useEffect(() => {
    // Calculate the width of the first column to determine scroll amount
    const firstColumn = columnsRef.current?.querySelector(".column-class-name"); // Replace with your actual column class name
    if (firstColumn) {
      columnWidthRef.current = firstColumn.offsetWidth;
    }

    const handleMouseDown = (e) => {
      isDownRef.current = true;
      columnsRef.current.classList.add("active");
      startXRef.current = e.pageX - columnsRef.current.offsetLeft;
      scrollLeftRef.current = columnsRef.current.scrollLeft;
    };

    const handleMouseLeave = () => {
      isDownRef.current = false;
      columnsRef.current.classList.remove("active");
    };

    const handleMouseUp = () => {
      isDownRef.current = false;
      columnsRef.current.classList.remove("active");
    };

    const handleMouseMove = (e) => {
      if (!isDownRef.current) return;
      e.preventDefault();
      const x = e.pageX - columnsRef.current.offsetLeft;
      const walk = (x - startXRef.current) * 1; // Control scroll speed by adjusting the multiplier
      columnsRef.current.scrollLeft = scrollLeftRef.current - walk;
    };

    const handleTouchStart = (e) => {
      startXRef.current = e.touches[0].pageX - columnsRef.current.offsetLeft;
      scrollLeftRef.current = columnsRef.current.scrollLeft;
    };

    const handleTouchMove = (e) => {
      const x = e.touches[0].pageX - columnsRef.current.offsetLeft;
      const walk = (x - startXRef.current) * 1; // Control scroll speed by adjusting the multiplier
      columnsRef.current.scrollLeft = scrollLeftRef.current - walk;
    };

    const handleKeyDown = (e) => {
      if (e.key === "ArrowLeft") {
        columnsRef.current.scrollBy({ left: -columnWidthRef.current, behavior: "smooth" });
      } else if (e.key === "ArrowRight") {
        columnsRef.current.scrollBy({ left: columnWidthRef.current, behavior: "smooth" });
      }
    };

    const columns = columnsRef.current;
    columns.addEventListener("mousedown", handleMouseDown);
    columns.addEventListener("mouseleave", handleMouseLeave);
    columns.addEventListener("mouseup", handleMouseUp);
    columns.addEventListener("mousemove", handleMouseMove);
    columns.addEventListener("touchstart", handleTouchStart);
    columns.addEventListener("touchmove", handleTouchMove);
    window.addEventListener("keydown", handleKeyDown);

    return () => {
      columns.removeEventListener("mousedown", handleMouseDown);
      columns.removeEventListener("mouseleave", handleMouseLeave);
      columns.removeEventListener("mouseup", handleMouseUp);
      columns.removeEventListener("mousemove", handleMouseMove);
      columns.removeEventListener("touchstart", handleTouchStart);
      columns.removeEventListener("touchmove", handleTouchMove);
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, []);

  const handleLearnMoreClick = () => {
    window.location.href = "#contactusform";
  };

  return (
    <>
      <div className="our-process-wrapper">
        <div className="our-process">Our Process</div>
      </div>
      <div className="lorem-ipsum-dolor1">
        We are a dedicated team offering comprehensive IT solutions designed to
        support your needs throughout the entire lifecycle of your project. Our
        commitment extends beyond delivery, providing transparent and reliable
        services to ensure your long-term success.
        <div className="pt-3 ">Slide to see the Process...  </div>
      </div>
      
      <div className={`service-columns-wrapper ${className}`}>
        <div className="service-columns" ref={columnsRef}>
          <Column
            className="column-class-name" // Ensure this matches your CSS class for columns
            processCardActionBtnTitle="Learn More"
            processCardNumber="1"
            processCardPara="Discovery phase involves understanding the client’s requirements and objectives. It includes gathering business requirements, identifying user needs, and determining project scope."
            processCardHeading="Discovery"
            propAlignSelf="unset"
            propWidth="120px"
            propPadding="0px 20px 0px 0px"
            frame68="/frame-68@2x.png"
            frame67="/frame-67@2x.png"
            onClick={handleLearnMoreClick}
          />
          <Column
            className="column-class-name"
            processCardActionBtnTitle="Learn More"
            processCardNumber="2"
            processCardPara="Research & Strategy phase focuses on analyzing market trends, competitors, and user behavior. It includes creating a strategic plan to achieve business goals and outlining project milestones."
            processCardHeading="Research & Strategy"
            propAlignSelf="unset"
            propWidth="235px"
            propPadding="0px 20px 0px 0px"
            frame68="/frame-68@2x.png"
            frame67="/frame-671@2x.png"
            onClick={handleLearnMoreClick}
          />
          <Column
            className="column-class-name"
            processCardActionBtnTitle="Learn More"
            processCardNumber="3"
            processCardPara="Design & Development phase involves creating the system architecture, designing user interfaces, and developing the application. It includes coding, integrating systems, and ensuring functionality."
            processCardHeading="Design & Development"
            propAlignSelf="unset"
            propWidth="420px"
            propPadding="0px 20px 0px 0px"
            frame68="/frame-68@2x.png"
            frame67="/frame-672@2x.png"
            onClick={handleLearnMoreClick}
          />
          <Column
            className="column-class-name"
            processCardActionBtnTitle="Learn More"
            processCardNumber="4"
            processCardPara="Delivery / Launch phase includes testing the application, fixing bugs, and deploying it to production. It ensures that the application is stable and ready for use by end users."
            processCardHeading="Delivery / Launch"
            propAlignSelf="stretch"
            propWidth="unset"
            propPadding="unset"
            frame68="/frame-68@2x.png"
            frame67="/frame-673@2x.png"
            onClick={handleLearnMoreClick}
          />
          <Column
            className="column-class-name"
            processCardActionBtnTitle="Learn More"
            processCardNumber="5"
            processCardPara="Support phase provides ongoing maintenance and support to address any issues that arise post-launch. It includes updates, troubleshooting, and enhancements to ensure continued performance."
            processCardHeading="Support"
            frame68="/frame-68@2x.png"
            frame67="/frame-673@2x.png"
            onClick={handleLearnMoreClick}
          />
        </div>
      </div>
    </>
  );
};

Ourprocess.propTypes = {
  className: PropTypes.string,
};

export default Ourprocess;
