import React, { useState } from "react";
import { Link } from 'react-scroll';
import FrameComponent5 from "./frame-component5";
import PropTypes from "prop-types";
import "./frame-component.css";
import InquiryFormModal from "./inquiry.jsx"; // Import the modal component

const FrameComponent = ({ className = "" }) => {
  const [modalShow, setModalShow] = useState(false); // State to control modal visibility

  const handleModalOpen = () => setModalShow(true);
  const handleModalClose = () => setModalShow(false);

  return (
    <div className={`lines-bg-sec-1-parent ${className}`}>
      <div className="lines-bg-sec-1 row">

      <div className="hero col-md-6 col-sm-12" >
                <div className="column">
                  <div className="content1">
                    <div className="medium-length-hero-container">
                      <p className="welcome-to">{`Crafting Digital `}</p>
                      <p className="welcome-to">Experiences</p>
                    </div>
                    <div className="lorem-ipsum-dolor2">
                      Transform your vision with cutting-edge IT solutions. We
                      turn ideas into reality with seamless design, development,
                      and support your partner for a smarter digital world.
                    </div>
                  </div>
                  <div className="actions1">
                    <button
                      className="button2"
                      onClick={handleModalOpen} // Open the modal on click
                    >
                      <div className="project-enquiry">Project Inquiry</div>
                      <img className="button-item" alt="" src="/arrow-11.svg" />
                    </button>
                    <Link to="servicesection" smooth={true} duration={50} className="button3">
                      <div className="services">Services</div>
                      <img className="button-item" alt="" src="/arrow-12.svg" />
                    </Link>
                  </div>
                </div>
              </div>

        <div className="content-wrapper col-md-6 col-sm-12">
          <div className="frame-parent6">
            <img className="frame-child549" alt="" src="/frame-25@2x.png" />
            <img className="frame-child550" alt="" src="/frame-21@2x.png" />
          </div>

          
            
                <div className="frame-parent10">
                  <div className="frame-wrapper1">
                    <div className="vector-parent">
                      <img className="line-icon" alt="" src="/line-9.svg" />
                      <img className="line-icon" alt="" src="/line-10.svg" />
                      <img className="line-icon" alt="" src="/line-11.svg" />
                    </div>
                  </div>
                  <div className="welcome">
                    <div className="dicons-group">
                      <img className="dicons2" alt="" src="/3dicons@2x.png" />
                      <div className="welcome-to-ashdip-container">
                        <p className="welcome-to">Welcome </p>
                        <p className="welcome-to"> to</p>
                        <p className="welcome-to">{'Ashdip IT '}</p>
                        <p className="welcome-to">Solutions</p>
                      </div>
                    </div>

                    <div className="map-pin">
                    <FrameComponent5
                      snackbarText="Pune, India"              
                      snackbarShowIcon
                    />
                  </div>

                  </div>

                 
          </div>
        </div>
      </div>
      <InquiryFormModal show={modalShow} handleClose={handleModalClose} />
    </div>
  );
};

FrameComponent.propTypes = {
  className: PropTypes.string,
};

export default FrameComponent;
