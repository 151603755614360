import PropTypes from "prop-types";
import "./frame-component5.css";

const FrameComponent5 = ({
  className = "",
  snackbarText = "Pune, India",
  snackbarShowIcon = true,
}) => {
  return (
    <div className={`icon-map-parent ${className}`}>
      {snackbarShowIcon && (
        <img className="icon-map" alt="" src="/icon--map.svg" />
      )}
      <div className="india">
        <div className="from-pimpri-india">{snackbarText}</div>
      </div>
    </div>
  );
};

FrameComponent5.propTypes = {
  className: PropTypes.string,
  snackbarText: PropTypes.string,
  snackbarShowIcon: PropTypes.bool,
};

export default FrameComponent5;
