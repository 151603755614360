import React, { useState, useEffect } from 'react';
import axiosInstance from '../../../services/AxiosInstance';
import { useLocation } from 'react-router-dom';
import { useNavigate } from 'react-router-dom/dist';
import {isLoggedIn} from "../../../auth";


const UpdateCodingQuestion = () => {
    const location = useLocation();
    const { id } = location.state;
    const navigate = useNavigate();

    useEffect(() => {
        if (!isLoggedIn()) {
            navigate("/adminlogin");
        }

    }, [navigate])



    const [question, setQuestion] = useState('');
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [success, setSuccess] = useState(false);

    useEffect(() => {
        // Fetch the coding question by ID when the component mounts
        const fetchQuestion = async () => {
            setLoading(true);
            try {
                const response = await axiosInstance.get(`/backend/coding/question/${id}`);
                setQuestion(response.data.question);
            } catch (err) {
                setError('Failed to fetch the coding question.');
            } finally {
                setLoading(false);
            }
        };

        fetchQuestion();
    }, [id]);

    const handleChange = (e) => {
        setQuestion(e.target.value);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);
        setError(null);
        setSuccess(false);

        try {
            const response = await axiosInstance.put(`/coding/question/${id}`, { question });
            if (response.status === 200 || response.status === 201) {
                setSuccess(true);
                setTimeout(() => {
                    navigate('/admin/show-coding-question')
                }, 1000);


            } else {
                setError('Failed to update the coding question.');
            }
        } catch (err) {
            console.error('Error details:', err.response ? err.response.data : err.message);
            setError('An error occurred while updating the coding question.');
        } finally {
            setLoading(false);
        }
    };

    return (
        <div className="max-w-xl mx-auto p-4 bg-white shadow-lg rounded-lg mt-10">
            <h2 className="text-2xl font-bold mb-6 text-center">Update Coding Question</h2>
            {loading && <p>Loading...</p>}
            {success && (
                <div className="mt-6 p-4 bg-green-100 text-green-700 rounded-lg">
                    Coding question successfully updated!
                </div>
            )}
            {error && (
                <div className="mt-6 p-4 bg-red-100 text-red-700 rounded-lg">
                    {error}
                </div>
            )}
            {!loading && (
                <form onSubmit={handleSubmit}>
                    <div className="mb-4">
                        <label className="block text-gray-700 font-semibold mb-2">Coding Question</label>
                        <textarea
                            name="question"
                            value={question}
                            onChange={handleChange}
                            className="w-full p-3 border border-gray-300 rounded-lg resize-none"
                            rows="4"
                            placeholder="Enter the coding question here"
                            required
                        />
                    </div>
                    <div className="text-center">
                        <button
                            type="submit"
                            className="bg-blue-500 text-white font-semibold py-2 px-4 rounded-lg hover:bg-blue-600 transition duration-300"
                            disabled={loading}
                        >
                            {loading ? 'Submitting...' : 'Submit'}
                        </button>
                    </div>
                </form>
            )}




        </div>
    );
};

export default UpdateCodingQuestion;
