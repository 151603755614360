import React from 'react'
import AddCodingForm from '../components/admin/addCodingQuestion/AddCodingForm'

const AddCodingPage = () => {
    return (
        <div>
            <AddCodingForm />
        </div>
    )
}

export default AddCodingPage