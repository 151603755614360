import { useMemo } from "react";
import PropTypes from "prop-types";
import "./column.css";

const Column = ({
  className = "",
  processCardActionBtnTitle = "Learn More",
  processCardNumber = "5",
  processCardPara = "Support phase provides ongoing maintenance and support to address any issues that arise post-launch. It includes updates, troubleshooting, and enhancements to ensure continued performance.",
  processCardHeading = "Support",
  frame68,
  frame67,
  propAlignSelf,
  propWidth,
  propPadding,
  propTop,
  propTransform,
  propAlignSelf1,
  propFlex,
  propDisplay,
  propMinWidth,
  propHeight,
  propDisplay1,
  propHeight1,
  propDisplay2,
  propWidth1,
  propHeight2,
  propAlignSelf2,
  propFlex1,
  propPadding1,
  propPosition,
  propAlignSelf3,
  propFlex2,
  propDisplay3,
  propMinWidth1,
  propHeight3,
  propPosition1,
  propMargin,
  propTop1,
  propRight,
  propBottom,
  propMaxHeight,
  propMinHeight,
}) => {
  const frameDivStyle = useMemo(() => {
    return {
      alignSelf: propAlignSelf,
      width: propWidth,
      padding: propPadding,
    };
  }, [propAlignSelf, propWidth, propPadding]);

  const frameIconStyle = useMemo(() => {
    return {
      top: propTop,
      transform: propTransform,
    };
  }, [propTop, propTransform]);

  const bStyle = useMemo(() => {
    return {
      alignSelf: propAlignSelf1,
      flex: propFlex,
      display: propDisplay,
      minWidth: propMinWidth,
    };
  }, [propAlignSelf1, propFlex, propDisplay, propMinWidth]);

  const headingStyle = useMemo(() => {
    return {
      height: propHeight,
      display: propDisplay1,
    };
  }, [propHeight, propDisplay1]);

  const textStyle = useMemo(() => {
    return {
      height: propHeight1,
      display: propDisplay2,
    };
  }, [propHeight1, propDisplay2]);

  const actionsStyle = useMemo(() => {
    return {
      width: propWidth1,
      height: propHeight2,
    };
  }, [propWidth1, propHeight2]);

  const button1Style = useMemo(() => {
    return {
      alignSelf: propAlignSelf2,
      flex: propFlex1,
      padding: propPadding1,
      position: propPosition,
    };
  }, [propAlignSelf2, propFlex1, propPadding1, propPosition]);

  const button2Style = useMemo(() => {
    return {
      alignSelf: propAlignSelf3,
      flex: propFlex2,
      display: propDisplay3,
      minWidth: propMinWidth1,
    };
  }, [propAlignSelf3, propFlex2, propDisplay3, propMinWidth1]);

  const iconChevronRightStyle = useMemo(() => {
    return {
      height: propHeight3,
      position: propPosition1,
      margin: propMargin,
      top: propTop1,
      right: propRight,
      bottom: propBottom,
      maxHeight: propMaxHeight,
      minHeight: propMinHeight,
    };
  }, [
    propHeight3,
    propPosition1,
    propMargin,
    propTop1,
    propRight,
    propBottom,
    propMaxHeight,
    propMinHeight,
  ]);

  const handleLearnMoreClick = () => {
     window.location.href = '#contactusform';
  };

  return (
    <div className={`column1 ${className}`}>
      <div className="section-title6">
        <div className="frame-parent19" style={frameDivStyle}>
          <img className="frame-child648" alt="" src={frame68} />
          <img
            className="frame-child649"
            alt=""
            src={frame67}
            style={frameIconStyle}
          />
        </div>
        <div className="content10">
          <div className="content-inner9">
            <div className="wrapper8">
              <b className="b6" style={bStyle}>
                {processCardNumber}
              </b>
            </div>
          </div>
          <div className="heading12" style={headingStyle}>
            {processCardHeading}
          </div>
          <div className="text6" style={textStyle}>
            {processCardPara}
          </div>
        </div>
      </div>
      <div className="actions9" style={actionsStyle}>
        <div className="button31">
          <div className="button32">Button</div>
        </div>
        <div className="button33" style={button1Style}>
          <div className="button34" style={button2Style} onClick={handleLearnMoreClick}>
            {processCardActionBtnTitle}
          </div>
          <img
            className="icon-chevron-right6"
            alt=""
            src="/icon--chevronright.svg"
            style={iconChevronRightStyle}
          />
        </div>
      </div>
    </div>
  );
};

Column.propTypes = {
  className: PropTypes.string,
  processCardActionBtnTitle: PropTypes.string,
  processCardNumber: PropTypes.string,
  processCardPara: PropTypes.string,
  processCardHeading: PropTypes.string,
  frame68: PropTypes.string,
  frame67: PropTypes.string,

  /** Style props */
  propAlignSelf: PropTypes.any,
  propWidth: PropTypes.any,
  propPadding: PropTypes.any,
  propTop: PropTypes.any,
  propTransform: PropTypes.any,
  propAlignSelf1: PropTypes.any,
  propFlex: PropTypes.any,
  propDisplay: PropTypes.any,
  propMinWidth: PropTypes.any,
  propHeight: PropTypes.any,
  propDisplay1: PropTypes.any,
  propHeight1: PropTypes.any,
  propDisplay2: PropTypes.any,
  propWidth1: PropTypes.any,
  propHeight2: PropTypes.any,
  propAlignSelf2: PropTypes.any,
  propFlex1: PropTypes.any,
  propPadding1: PropTypes.any,
  propPosition: PropTypes.any,
  propAlignSelf3: PropTypes.any,
  propFlex2: PropTypes.any,
  propDisplay3: PropTypes.any,
  propMinWidth1: PropTypes.any,
  propHeight3: PropTypes.any,
  propPosition1: PropTypes.any,
  propMargin: PropTypes.any,
  propTop1: PropTypes.any,
  propRight: PropTypes.any,
  propBottom: PropTypes.any,
  propMaxHeight: PropTypes.any,
  propMinHeight: PropTypes.any,
};

export default Column;
