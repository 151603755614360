import MapsFullMap from "./maps-full-map";
import PropTypes from "prop-types";
import { Link } from 'react-router-dom';
import { Link as ScrollLink } from 'react-scroll';
import "./card-container.css";
import 'bootstrap/dist/css/bootstrap.min.css';

const CardContainer = ({ className = "" }) => {

  const whatsappNumber = "+91 93092 34245"; // Replace with your desired WhatsApp number
  const whatsappMessage = "Hello, I would like to know more about your services."; // Your predefined message

  return (
    <div className={`card-container ${className}`}>
      <div className="card-wrapper">
        <div className="card">
          <div className="column2">
            <div className="logo-details">
              <div className="logo-image">
                <img className="symbol-logo-version-32" alt="" src="/symbol-logo-version-32@2x.png" />
              </div>
              <div className="brand-name">
                <img className="ashdip-icon2" alt="" src="/ashdip.svg" />
                <img className="logo-container-icon" alt="" src="/logo-container@2x.png" />
                <div className="it-solutions2">IT Solutions</div>
              </div>
            </div>

            <div className="navigation">
              <a href="/" className="home2">Home</a>
              <a href="/" className="about2">About</a>
              <ScrollLink to="servicesection" smooth={true} duration={50} className="services1" style={{cursor:"pointer"}}>Services</ScrollLink>
              <Link to="/career" style={{ textDecoration: 'none'}} >Career</Link>
            </div>

            <div className="content12">
              <div className="content13">
                <div className="address">Address:</div>
                <div className="p-j-chambers">
                  P. J. Chambers, 304/2, Nehru Nagar Rd, Atma Nagar, Kharalwadi,
                  Pimpri Colony, Pune, Pimpri-Chinchwad, Maharashtra 411018
                </div>
              </div>
              <div className="content13">
                <div className="contact">Contact:</div>
                <div className="container3">
                  <div className="email-id">9309234245</div>
                  <div className="infoashdipitsolutionsin">
                    info@ashdipitsolutions.in
                  </div>
                </div>
              </div>
              <div className="social-links4">
                <a href="https://www.facebook.com/share/Gdo7TDLddQnY8tAF/?mibextid=qi2Omg">
                  <img className="icon-facebook" alt="" src="/icon--facebook1.svg" />
                </a>
                <a href="https://www.instagram.com/ashdipit?igsh=MWV3cmZpbGc5cGRwYw==">
                  <img className="icon-facebook" alt="" src="/icon--instagram1.svg" />
                </a>
                <a href="https://x.com/Ashdipit?s=09">
                  <img className="icon-facebook" alt="" src="/icon--x1.svg" />
                </a>
                <a href="https://www.linkedin.com/in/ashok-sonawane-81a693183?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=ios_app">
                  <img className="icon-facebook" alt="" src="/icon--linkedin1.svg" />
                </a>
                <a href="/">
                  <img className="icon-facebook" alt="" src="/icon--youtube.svg" />
                </a>
                <a href={`https://wa.me/${whatsappNumber}?text=${encodeURIComponent(whatsappMessage)}`} 
                    target="_blank" rel="noopener noreferrer">
                  <img className="icon-whatsapp" alt="WhatsApp" src="/icon--whatsapp.svg" />
                </a>
              </div>
            </div>
          </div>
          <div className="locate-us-parent">
            <div className="locate-us">LOCATE US</div>
            <MapsFullMap />
          </div>
        </div>
        <div className="credits">
          <div className="row">
            <div className="ashdip-it-solutions col-md-3 col-sm-12">
              © 2024 Ashdip It Solutions
            </div>
            <div className="footer-links col-md-9 col-sm-12">
              <div className="privacy-policy">Privacy Policy</div>
              <div className="terms-of-service">Terms of Service</div>
              <div className="cookies-settings">Cookies Settings</div>
              <div className="designed-develop">{`Designed & Develop @AshdipITSolutions`}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

CardContainer.propTypes = {
  className: PropTypes.string,
};

export default CardContainer;
