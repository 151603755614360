import React from 'react'
import Dashboard from '../components/admin/dashboard/Dashboard'

const AdminDashboard = () => {
  return (
    <div className="">

      <Dashboard />
    </div>
  )
}

export default AdminDashboard