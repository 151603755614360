import React, { useState } from "react";
import { Modal, Button, Form } from "react-bootstrap";
import PropTypes from "prop-types";
import axiosInstance from "../services/AxiosInstance"; 

const InquiryFormModal = ({ show, handleClose }) => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    phone: '',
    companyName: '',
    projectDescription: '',
    budget: ''
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
        await axiosInstance.post("/backend/saveprojectinquiry", formData); 
    console.log("Form submitted:", formData);
    handleClose();  // Close the modal after submission
    }
    catch (error) {
        console.error("There was an error submitting the form!", error);
    }
  };

  return (
    <Modal show={show} onHide={handleClose}>
      <Modal.Header closeButton>
      <Modal.Title style={{ fontSize: '30px', fontWeight: '800' }}>
        Project Inquiry
    </Modal.Title>
    </Modal.Header>
      <Modal.Body>
        <Form onSubmit={handleSubmit}>
          <Form.Group controlId="formName">
            <Form.Label>Name</Form.Label>
            <Form.Control
              type="text"
              name="name"
              value={formData.name}
              onChange={handleInputChange}
            />
          </Form.Group>
          <Form.Group controlId="formEmail" className="mt-3">
            <Form.Label>Email</Form.Label>
            <Form.Control
              type="email"
              name="email"
              value={formData.email}
              onChange={handleInputChange}
              required
            />
          </Form.Group>
          <Form.Group controlId="formPhone" className="mt-3">
            <Form.Label>Phone</Form.Label>
            <Form.Control
              type="text"
              name="phone"
              value={formData.phone}
              onChange={handleInputChange}
            />
            </Form.Group>
            <Form.Group controlId="formCompanyName" className="mt-3">
            <Form.Label>Company Name</Form.Label>
            <Form.Control
              type="text"
              name="companyName"
              value={formData.companyName}
              onChange={handleInputChange}
              required
            />
          </Form.Group>

          <Form.Group controlId="formProjectDescription" className="mt-3">
            <Form.Label>Project Description</Form.Label>
            <Form.Control
              as="textarea"
              name="projectDescription"
              value={formData.projectDescription}
              onChange={handleInputChange}
              rows={3}
              required
            />
          </Form.Group>
          <Form.Group controlId="formBudget" className="mt-3">
            <Form.Label>Budget</Form.Label>
            <Form.Control
              type="text"
              name="budget"
              value={formData.budget}
              onChange={handleInputChange}
            />
          </Form.Group>
          <Button variant="primary" type="submit" className="float-end mt-3">
            Submit
          </Button>
        </Form>
      </Modal.Body>
    </Modal>
  );
};

InquiryFormModal.propTypes = {
  show: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
};

export default InquiryFormModal;
