import PropTypes from "prop-types";
import "./section.css";

const Section = ({
  className = "",
  projects,
  pentagon,
  pentagon2,
  pentagon3,
  pentagon5,
}) => {
  return (
    <div className={`section ${className}`}>
      <div className="item">
        <div className="projects">{projects}</div>
      </div>
      <div className="item1">
        <img className="pentagon-icon" alt="" src={pentagon} />
        <div className="label">Label</div>
      </div>
      <div className="item2">
        <img className="pentagon-icon" alt="" src="/pentagon.svg" />
        <div className="label">Label</div>
      </div>
      <div className="item3">
        <img className="pentagon-icon" alt="" src={pentagon2} />
        <div className="label2">Label</div>
      </div>
      <div className="item4">
        <img className="pentagon-icon" alt="" src={pentagon3} />
        <div className="label2">Label</div>
      </div>
      <div className="item3">
        <img className="pentagon-icon" alt="" src="/pentagon.svg" />
        <div className="label2">Label</div>
      </div>
      <div className="item6">
        <img className="pentagon-icon5" alt="" src={pentagon5} />
        <div className="label5">Label</div>
      </div>
      <div className="item6">
        <img className="pentagon-icon5" alt="" src="/pentagon.svg" />
        <div className="label5">Label</div>
      </div>
    </div>
  );
};

Section.propTypes = {
  className: PropTypes.string,
  projects: PropTypes.string,
  pentagon: PropTypes.string,
  pentagon2: PropTypes.string,
  pentagon3: PropTypes.string,
  pentagon5: PropTypes.string,
};

export default Section;
