import React, { useEffect, useState } from 'react';
import './Sidebar.css';
import { Link, Outlet, useNavigate } from 'react-router-dom';

import logo from "../../../assets/images/Ashdiplogowhite.png";

import { isLoggedIn, doLogout } from '../../../auth';

const Sidebar = () => {
    const navigate = useNavigate();
    const [isExpanded, setIsExpanded] = useState(false);

    useEffect(() => {
        if (!isLoggedIn()) {
            navigate("/adminlogin");
        }
    }, [navigate]);

    const handleToggle = () => {
        setIsExpanded(!isExpanded);
    };

    const handleLogout = () => {
        doLogout(() => navigate("/adminlogin"));
    }

    return (
        <div className={`wrapper-sidebar ${isExpanded ? 'expand' : ''}`}>
            <aside id="sidebar" className={`sidebar ${isExpanded ? 'expand' : ''}`}>
                <div className="d-flex">
                    <button className="toggle-btn" type="button" onClick={handleToggle}>
                        <i className="lni lni-grid-alt"></i>
                    </button>
                    <div className="sidebar-logo flex justify-center">
                        <Link to='/admin/dashboard'>
                            <img src={logo} alt="Brand logo" />
                        </Link>
                    </div>
                </div>
                <ul className="sidebar-nav">
                    <li className="sidebar-item">
                        <Link to="/admin/dashboard" className="sidebar-link" title='Dashboard'>
                            <i className="fa-solid fa-gauge"></i>
                            <span>Dashboard</span>
                        </Link>
                    </li>
                    <li className="sidebar-item">
                        <Link to="/admin/add-mcq" className="sidebar-link" title='Add MCQ Question'>
                            <i className="fa-solid fa-plus-square"></i>
                            <span>Add MCQ Question</span>
                        </Link>
                    </li>
                    <li className="sidebar-item">
                        <Link to="/admin/add-coding" className="sidebar-link" title='Add Coding Question'>
                            <i className="fa-solid fa-keyboard"></i>
                            <span>Add Coding Question</span>
                        </Link>
                    </li>
                    <li className="sidebar-item">
                        <Link to="/admin/show-mcq" className="sidebar-link" title='Show MCQ'>
                            <i className="fa-solid fa-list"></i>
                            <span>Show MCQ</span>
                        </Link>
                    </li>
                    <li className="sidebar-item">
                        <Link to="/admin/show-coding-question" className="sidebar-link" title='Show Coding Questions'>
                            <i className="fa-solid fa-code"></i>
                            <span>Show Coding Questions</span>
                        </Link>
                    </li>
                    <li className="sidebar-item">
                        <Link to="/admin/contact-inquiries" className="sidebar-link" title='Contact Inquiries'>
                            <i className="fa-solid fa-envelope"></i>
                            <span>Contact Inquiries</span>
                        </Link>
                    </li>
                    <li className="sidebar-item">
                        <Link to="/admin/project-inquiries" className="sidebar-link" title='Project Inquiries'>
                            <i className="fa-solid fa-folder-open"></i>
                            <span>Project Inquiries</span>
                        </Link>
                    </li>
                </ul>
                <div className="sidebar-footer text-white pb-4 ps-2">
                    <button onClick={handleLogout} className="sidebar-link">
                        <i className="lni lni-exit"></i>
                        <span>Logout</span>
                    </button>
                </div>
            </aside>
            <div className="main p-5 mt-3 mr-3">
                <Outlet />
            </div>
        </div>
    );
};

export default Sidebar;
