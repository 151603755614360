import React from 'react'
import { Link } from 'react-router-dom'
import logo from '../../assets/images/Ashdiplogowhite.png';

const DetailNavbar = () => {
    return (
        //    Navbar
        <nav className="bg-gray-800 p-4" style={{height:'5rem'}}>
            <div className="container mx-auto flex justify-between items-center">
                {/* Left Section: Contact Info */}
               

                <div className=' col-sm-12 col-md-6'>
                        <img src={logo} alt='logo' width="200" style={{ position: 'absolute', top:'0px' , left:'35px' }}  />
                </div>

                {/* Right Section: Social Icons & Login Button */}
                <div className="items-center space-x-4 col-sm-12 col-md-6 text-end" >
                    {/* Social Icons  */}
                    <Link to="https://www.facebook.com/share/Gdo7TDLddQnY8tAF/?mibextid=qi2Omg" className="text-white">
                        <i className="fa-brands fa-facebook"></i>
                    </Link>
                    <Link to="https://www.instagram.com/ashdipit?igsh=MWV3cmZpbGc5cGRwYw==" className="text-white">
                        <i className="fa-brands fa-instagram"></i>
                    </Link>
                    <Link to="https://www.linkedin.com/in/ashok-sonawane-81a693183?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=ios_app" className="text-white">
                        <i className="fa-brands fa-linkedin"></i>
                    </Link>
                    {/* < Login Button */}
                    <Link to="/adminlogin" className="bg-blue-500 text-white py-2 px-4 rounded hover:bg-blue-600">
                        Login
                    </Link>
                </div>
            </div>
        </nav>

    )
}

export default DetailNavbar