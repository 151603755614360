import React, { useState } from 'react';
import './adminloginpage.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import axiosInstance from '../services/AxiosInstance';
import {  useNavigate } from 'react-router-dom';

const AdminLoginPage = () => {
    const navigate = useNavigate();


    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [error, setError] = useState('');
    const [success, setSuccess] = useState('');
    const [loading, setLoading] = useState(false);

    const handleSubmit = async (e) => {
        e.preventDefault();
        setError('');
        setSuccess('');
        setLoading(true);

        try {
            const response = await axiosInstance.post('/backend/auth/login', { email, password });
            if (response.data.jwtToken) {
                localStorage.setItem('token', response.data.jwtToken);
                setSuccess('Login successful! Redirecting...');
                setTimeout(() => navigate('/admin/dashboard'), 2000);
            }
        } catch (error) {
            setError(error.response?.data?.message || 'Login failed. Credentials Invalid.');
        } finally {
            setLoading(false);
        }
        
        setEmail('');
        setPassword('');
    };

    return (
        <div className="login-container">
            <div className="p-4">
                <img className="symbol-logoadmin" alt="" src="/symbol-logo-version-3@2x.png" />
                <img className="symbol-logoadmin-letter" alt="" src="/adminpanellogo.svg" />
            </div>
            <div className="login-form position-absolute top-50 start-50 translate-middle">
                <h2 className="login-title">Welcome</h2>
                <h5 className="login-title pb-5">PLEASE LOGIN TO ADMIN DASHBOARD</h5>
                {error && <div className="alert alert-danger">{error}</div>}
                {success && <div className="alert alert-success">{success}</div>}
                <form onSubmit={handleSubmit}>
                    <input 
                        type="email" 
                        placeholder="Email" 
                        className="login-input" 
                        value={email} 
                        onChange={(e) => setEmail(e.target.value)}
                        required 
                    />
                    <input 
                        type="password" 
                        placeholder="Password" 
                        className="login-input" 
                        value={password} 
                        onChange={(e) => setPassword(e.target.value)}
                        required 
                    />
                    <button type="submit" className="login-button mt-3" disabled={loading}>
                        {loading ? 'Logging in...' : 'LOGIN'}
                    </button>
                </form>
            </div>
        </div>
    );
};

export default AdminLoginPage;
