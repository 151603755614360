import React from 'react'
import ShowMcq from '../components/admin/showMcq/ShowMcq'

const ShowMcqPage = () => {
    return (
        <div>
            <ShowMcq />
        </div>
    )
}

export default ShowMcqPage