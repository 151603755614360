import React, { useState, useEffect } from 'react';
import axiosInstance from '../../../services/AxiosInstance';
import { useLocation } from 'react-router-dom';
import { useNavigate } from 'react-router-dom/dist';
import {isLoggedIn} from "../../../auth";


const UpdateMcqQuestion = () => {
    const location = useLocation();
    const { id } = location.state;

    const navigate = useNavigate();

    useEffect(() => {
        if (!isLoggedIn()) {
            navigate("/adminlogin");
        }

    }, [navigate])
    const [formData, setFormData] = useState({
        title: '',
        question: '',
        optionA: '',
        optionB: '',
        optionC: '',
        optionD: '',
        correctAnswerOption: '',
        correctAnswer: '',
    });

    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [success, setSuccess] = useState(false);

    useEffect(() => {
        // Fetch the MCQ data by ID when the component mounts
        const fetchMcqData = async () => {
            setLoading(true);
            try {
                const response = await axiosInstance.get(`/backend/mcq/question/${id}`);
                const data = response.data;
                setFormData({
                    title: data.title,
                    question: data.question,
                    optionA: data.optionA,
                    optionB: data.optionB,
                    optionC: data.optionC,
                    optionD: data.optionD,
                    correctAnswerOption: determineCorrectAnswerOption(data),
                    correctAnswer: data.correctAnswer,
                });
            } catch (err) {
                setError('Failed to fetch the MCQ data.');
            } finally {
                setLoading(false);
            }
        };

        fetchMcqData();
    }, [id]);

    const determineCorrectAnswerOption = (data) => {
        // Determine which option is the correct answer
        if (data.correctAnswer === data.optionA) return 'optionA';
        if (data.correctAnswer === data.optionB) return 'optionB';
        if (data.correctAnswer === data.optionC) return 'optionC';
        if (data.correctAnswer === data.optionD) return 'optionD';
        return '';
    };

    const handleChange = (e) => {
        const { name, value } = e.target;

        if (name === 'correctAnswerOption') {
            setFormData({
                ...formData,
                correctAnswerOption: value,
                correctAnswer: formData[value],
            });
        } else {
            setFormData({ ...formData, [name]: value });
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);
        setError(null);
        setSuccess(false);

        try {
            const response = await axiosInstance.put(`/mcq/question/update/${id}`, formData);

            if (response.status === 200 || response.status === 201) {
                setSuccess(true);
                setTimeout(() => {
                    navigate("/admin/show-mcq");
                }, 2000);
            } else {
                setError('Failed to update the MCQ.');
            }
        } catch (err) {
            setError('An error occurred while updating the MCQ.');
        } finally {
            setLoading(false);
        }
    };

    return (
        <div className="max-w-xl mx-auto p-4 bg-white shadow-lg rounded-lg mt-5">
            <h2 className="text-2xl font-bold mb-6 text-center">Update MCQ</h2>
            {loading && <p>Loading...</p>}
            {success && (
                <div className="mt-6 p-4 bg-green-100 text-green-700 rounded-lg">
                    MCQ successfully updated!
                </div>
            )}

            {error && (
                <div className="mt-6 p-4 bg-red-100 text-red-700 rounded-lg">
                    {error}
                </div>
            )}
            {!loading && (
                <form onSubmit={handleSubmit}>
                    <div className="mb-4">
                        <label className="block text-gray-700 font-semibold mb-2">Job Title</label>
                        <select
                            name="title"
                            value={formData.title}
                            onChange={handleChange}
                            className="w-full p-2 border border-gray-300 rounded-lg"
                            required
                        >
                            <option value="" disabled>Select a Job title</option>
                            <option value="java">Java</option>
                            <option value="dm">Digital Marketing</option>
                            <option value="uiux">UI/UX</option>
                        </select>
                    </div>

                    <div className="mb-4">
                        <label className="block text-gray-700 font-semibold mb-2">Question</label>
                        <input
                            type="text"
                            name="question"
                            value={formData.question}
                            onChange={handleChange}
                            className="w-full p-2 border border-gray-300 rounded-lg"
                            placeholder="Enter the question"
                            required
                        />
                    </div>

                    {['A', 'B', 'C', 'D'].map((option) => (
                        <div className="mb-4" key={option}>
                            <label className="block text-gray-700 font-semibold mb-2">Option {option}</label>
                            <input
                                type="text"
                                name={`option${option}`}
                                value={formData[`option${option}`]}
                                onChange={handleChange}
                                className="w-full p-2 border border-gray-300 rounded-lg"
                                placeholder={`Enter option ${option}`}
                                required
                            />
                        </div>
                    ))}

                    <div className="mb-4">
                        <label className="block text-gray-700 font-semibold mb-2">Correct Answer</label>
                        <select
                            name="correctAnswerOption"
                            value={formData.correctAnswerOption}
                            onChange={handleChange}
                            className="w-full p-2 border border-gray-300 rounded-lg"
                            required
                        >
                            <option value="" disabled>Select the correct answer</option>
                            <option value="optionA">Option A</option>
                            <option value="optionB">Option B</option>
                            <option value="optionC">Option C</option>
                            <option value="optionD">Option D</option>
                        </select>
                    </div>
                    {formData.correctAnswerOption && (
                        <div className="mt-6 p-4 bg-green-100 text-green-700 rounded-lg">
                            <strong>Correct Answer:</strong> {formData.correctAnswer}
                        </div>
                    )}

                    <div className="text-center">
                        <button
                            type="submit"
                            className="bg-blue-500 text-white font-semibold py-2 px-4 rounded-lg hover:bg-blue-600 transition duration-300"
                            disabled={loading}
                        >
                            {loading ? 'Submitting...' : 'Submit'}
                        </button>
                    </div>
                </form>
            )}


        </div>
    );
};

export default UpdateMcqQuestion;
