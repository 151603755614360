

import {BrowserRouter , Route, Routes ,useLocation} from 'react-router-dom';

import Desktop1280x from "./pages/desktop1280x";
import AdminLoginPage from "./pages/adminloginpage"

import './App.css';
import DetailNavbar from './components/detailNavbar/DetailNavbar';
import Navbar from './components/navbar/Navbar';
import Sidebar from './components/admin/sidebar/Sidebar';
// import Home from './pages/Home';
import CareerPage from './pages/CareerPage';
import ApplyNowPage from './pages/ApplyNowPage';
import StartTestpage from './pages/StartTestpage';
import McqTestPage from './pages/McqTestPage';
import McqTestQuestion from './components/mcqTestQuestion/McqTestQuestion';
import CodingTestInstructionPage from './pages/CodingTestInstructionPage';
import CodingQuestionPage from './pages/CodingQuestionPage';
import UploadDocumentPage from './pages/UploadDocumentPage';
import AdminDashboard from "./pages/AdminDashboard";
import DetailsTable from './components/admin/detailsTable/DetailsTable';
import LoginPage from './pages/LoginPage';
import AddMcqPage from './pages/AddMcqPage';
import AddCodingPage from './pages/AddCodingPage';
import ShowMcqPage from './pages/ShowMcqPage';
import ShowCodingQuestionPage from './pages/ShowCodingQuestionPage';
import UpdateMcqPage from './pages/UpdateMcqPage';
import UpdateCodingQuestionPage from './pages/UpdateCodingQuestionPage';
import ContactInquiriesPage from './pages/ContactInquiriesPage';
import ProjectInquiriesPage from './pages/ProjectInquiriesPage';


const Layout = ({ children }) => {
    const location = useLocation();
    const isAdminRoute = location.pathname.startsWith('/admin');

    return (
        <>
            {!isAdminRoute && <DetailNavbar />}
            {!isAdminRoute && <Navbar />}
            <main style={{backgroundColor:'whitesmoke', paddingBottom:'20px'}} >{children}</main>
        </>
    );
};


	const App = () => {

    return (
        <BrowserRouter >
            <Routes>
                {/* public routes */}
				<Route path="/adminlogin" element={<AdminLoginPage />} />

				 <Route path="/" element={<Desktop1280x />} />
				
				
                <Route path='/career' element={<Layout><CareerPage /></Layout>} />
                <Route path='/login' element={<Layout><LoginPage /></Layout>} />
                {/* <Route path='/home' element={<Layout><Home /></Layout>} /> */}

                {/* protected routes */}
				<Route path="/admindashboard" element={<Layout><AdminDashboard /></Layout>} />						
				
                <Route path="/apply/:title" element={<Layout><ApplyNowPage /></Layout>} />
                <Route path='/start-test' element={<Layout><StartTestpage /></Layout>} />
                <Route path='/Instructions' element={<Layout><McqTestPage /></Layout>} />
                <Route path='/mcq-test' element={<Layout><McqTestQuestion /></Layout>} />
                <Route path='/coding-instruction' element={<Layout><CodingTestInstructionPage /></Layout>} />
                <Route path='/coding-test' element={<Layout><CodingQuestionPage /></Layout>} />
                <Route path='/upload-document' element={<Layout><UploadDocumentPage /></Layout>} />

                {/* Admin routes with Sidebar */}
                <Route path='/admin/*' element={<Sidebar />}>
                    {/* <Route path='/admin/dashboard/*'  /> */}
                    <Route path='dashboard' element={<AdminDashboard />} />
                    <Route path='dashboard/:category' element={<DetailsTable />} />
                    <Route path='add-mcq' element={<AddMcqPage />} />
                    <Route path='add-coding' element={<AddCodingPage />} />
                    <Route path='show-mcq' element={<ShowMcqPage />} />
                    <Route path='show-coding-question' element={<ShowCodingQuestionPage />} />
                    <Route path='contact-inquiries' element={<ContactInquiriesPage />} />
                    <Route path='project-inquiries' element={<ProjectInquiriesPage />} />
                    <Route path='update-mcq' element={<UpdateMcqPage />} />
                    <Route path='update-coding-question' element={<UpdateCodingQuestionPage />} />
                    <Route path='upload-documents' element={<UploadDocumentPage />} />
                    {/* <Route path='users' element={<AdminDashboard />} /> */}
                </Route>



            </Routes>
        </BrowserRouter >
    );
}

export default App;
