import React, { useState, useEffect } from 'react';
import axiosInstance from '../../../services/AxiosInstance';
import { useNavigate } from 'react-router-dom';
import { isLoggedIn } from "../../../auth";

const ShowProjectInquiries = () => {
    const navigate = useNavigate();

    useEffect(() => {
        if (!isLoggedIn()) {
            navigate("adminlogin");
        }
    }, [navigate]);

    const [inquiries, setInquiries] = useState([]);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [showModal, setShowModal] = useState(false);
    const [selectedInquiryId, setSelectedInquiryId] = useState(null);
    const [successMessage, setSuccessMessage] = useState('');

    useEffect(() => {
        fetchInquiries();
    }, []);

    const fetchInquiries = async () => {
        setLoading(true);
        setError(null);
        setSuccessMessage('');
        try {
            const response = await axiosInstance.get(`/backend/getprojectinquiry`);
            setInquiries(response.data);
        } catch (err) {
            setError('Failed to fetch project inquiries.');
        } finally {
            setLoading(false);
        }
    };

    const handleDelete = async () => {
        setLoading(true);
        try {
            await axiosInstance.delete(`/backend/deleteprojectinquiry/${selectedInquiryId}`);
            setInquiries(inquiries.filter(inquiry => inquiry.id !== selectedInquiryId));
            setShowModal(false);
            setSuccessMessage('Project Inquiry deleted successfully.');
        } catch (err) {
            setError('Failed to delete project inquiry.');
        } finally {
            setLoading(false);
        }
    };

    const handleUpdate = (id) => {
        navigate('/admin/update-projectinquiry', { state: { id } });
    };

    const openModal = (id) => {
        setSelectedInquiryId(id);
        setShowModal(true);
    };

    const closeModal = () => {
        setShowModal(false);
        setSelectedInquiryId(null);
    };

    return (
        <div className="p-4">
            <h2 className="text-2xl font-bold mb-4">Project Inquiries Table</h2>

            {loading && <p>Loading...</p>}
            {error && <p className="text-red-500">{error}</p>}
            {successMessage && <p className="text-green-500">{successMessage}</p>}

            {!loading && !error && inquiries.length > 0 && (
                <div className="overflow-auto rounded-lg" style={{ maxHeight: '500px' }}>
                    <table className="min-w-full bg-white border border-gray-300 rounded-lg shadow-md">
                        <thead>
                            <tr>
                                <th className="px-4 py-2 border-b">#</th>
                                <th className="px-4 py-2 border-b">Name</th>
                                <th className="px-4 py-2 border-b">Email</th>
                                <th className="px-4 py-2 border-b">Phone</th>
                                <th className="px-4 py-2 border-b">Company</th>
                                <th className="px-4 py-2 border-b">Project Description</th>
                                <th className="px-4 py-2 border-b">Budget</th>
                                <th className="px-4 py-2 border-b">Actions</th>
                            </tr>
                        </thead>
                        <tbody>
                            {inquiries.map((inquiry, index) => (
                                <tr key={inquiry.id}>
                                    <td className="px-4 py-2 border-b">{index + 1}</td>
                                    <td className="px-4 py-2 border-b">{inquiry.name}</td>
                                    <td className="px-4 py-2 border-b">{inquiry.email}</td>
                                    <td className="px-4 py-2 border-b">{inquiry.phone}</td>
                                    <td className="px-4 py-2 border-b">{inquiry.companyName}</td>
                                    <td className="px-4 py-2 border-b">
                                        <pre className='w-96 whitespace-pre-wrap break-words'>{inquiry.projectDescription}</pre>
                                    </td>
                                    <td className="px-4 py-2 border-b">{inquiry.budget}</td>
                                    <td className="px-4 py-2 border-b">
                                        <div className="flex justify-center items-center">
                                            <button
                                                onClick={() => handleUpdate(inquiry.id)}
                                                className="bg-yellow-500 text-white px-2 py-1 rounded mr-2"
                                            >
                                                <i className="fa-solid fa-pen-to-square"></i>
                                            </button>
                                            <button
                                                onClick={() => openModal(inquiry.id)}
                                                className="bg-red-500 text-white px-2 py-1 rounded"
                                            >
                                                <i className="fa-solid fa-trash"></i>
                                            </button>
                                        </div>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            )}

            {!loading && !error && inquiries.length === 0 && <p>No Project Inquiries found.</p>}

            {showModal && (
                <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center">
                    <div className="bg-white p-6 rounded-lg shadow-lg">
                        <h3 className="text-lg font-bold mb-4">Confirm Deletion</h3>
                        <p>Are you sure you want to delete this Project Inquiry?</p>
                        <div className="mt-4 flex justify-end">
                            <button
                                onClick={closeModal}
                                className="bg-green-500 text-white px-4 py-2 rounded mr-2"
                            >
                                Cancel
                            </button>
                            <button
                                onClick={handleDelete}
                                className="bg-red-500 text-white px-4 py-2 rounded"
                            >
                                Delete
                            </button>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

export default ShowProjectInquiries;
