//is logged in
export const isLoggedIn = ()=> {
    let data = localStorage.getItem("token");
    if (data != null) {
        return true;
    }else{
        return false;
    }
}



// do login

export const doLogin=(data,next) =>{
    localStorage.setItem("token", data);
    console.log(data);
    
    next();
}


// do logout

export const doLogout = (next) => {
    localStorage.removeItem("token");
    next();
}

