import AccordionItem from "./accordion-item";
import PropTypes from "prop-types";
import { Link as ScrollLink } from 'react-scroll';
import "./faq-questions.css";

const FrameComponent3 = ({ className = "" }) => {
  return (
    <div className={`faq-3-wrapper ${className}`}>
      <div className="faq-3">
        <div className="container2">
          <div className="section-title7">
            <div className="content11">
              <div className="faqs">FAQs</div>
              <div className="text8">
                Common questions about our IT solutions and services.
              </div>
            </div>
           <ScrollLink to="contactussection" smooth={true} duration={50} className="button37" style={{cursor:"pointer"}}>
              <div className="reach-us1">Reach Us</div>
              <img className="button-child4" alt="" src="/arrow-11.svg" />
            </ScrollLink>

            <img className="" alt="" src="/faq3dicon.png" />
          </div>
          <div className="accordion">
            <AccordionItem 
              question="What types of IT solutions do you provide?" 
              answer="We offer a range of IT solutions including software development, system integration, cloud services, cybersecurity, and IT consulting. Our services are designed to address various business needs and help you achieve your digital transformation goals." 
            />
            <AccordionItem 
              question="How do you ensure the quality of your software?" 
              answer="We follow a rigorous quality assurance process that includes automated and manual testing, code reviews, and continuous integration. Our team employs industry best practices to ensure that our software is reliable, secure, and meets the highest standards." 
            />
            <AccordionItem 
              question="What is your approach to project management?" 
              answer="We use agile project management methodologies to ensure flexibility and responsiveness throughout the development process. Our approach involves regular client feedback, iterative development, and clear communication to keep projects on track and aligned with your goals." 
            />
            <AccordionItem 
              question="How do you handle data security and privacy?" 
              answer="Data security and privacy are top priorities for us. We implement robust security measures, including encryption, access controls, and regular security audits, to protect your data. Additionally, we comply with relevant data protection regulations and industry standards." 
            />
            <AccordionItem 
              question="Can you provide support and maintenance after the project is completed?" 
              answer="Yes, we offer ongoing support and maintenance services to ensure the continued performance and reliability of your IT solutions. Our support includes bug fixes, updates, and enhancements as needed to keep your systems running smoothly." 
            />
          </div>
        </div>
      </div>
    </div>
  );
};

FrameComponent3.propTypes = {
  className: PropTypes.string,
};

export default FrameComponent3;
