
import Avatar from "./avatar";
import Divider from "./divider";
import Section from "./section";
import ButtonSmall from "./button-small";
import OurService from "./our-service";
import PropTypes from "prop-types";
import "./service-container.css";

const ServiceContainer = ({ className = "" }) => {
  return (
    <><div className="our-services1">Our Services</div>
    <div className="lorem-ipsum-dolor">We are a creative team specializing in end-to-end IT solutions. From initial design to long-term support, we offer transparent and reliable services to guide you every step of the way.

    </div>
    <section id = "servicesection" className={`service-container ${className}`} >

      <div className="service-content">
        <div className="gradient-circle" />
        <div className="content3">
          <div className="our-service1">
            <div className="section-title1">
              <div className="icon-relume1">
                <div className="wrapper1">
                  <b className="b1">1</b>
                </div>
              </div>
              <div className="content4">
                <div className="content-items">
                  <div className="inner-content-items">
                    <div className="inner-content-items-child" />
                    <div className="inner-content-items-item" />
                    <div className="fab-extended2">
                      <img
                        className="leading-icon4"
                        alt=""
                        src="/leading-icon.svg" />
                      <div className="add-more1">Add More</div>
                      <img
                        className="leading-icon5"
                        alt=""
                        src="/leading-icon1@2x.png" />
                    </div>
                    <div className="fab-extended3">
                      <div className="new-item1">New Item</div>
                      <img
                        className="leading-icon4"
                        alt=""
                        src="/leading-icon2.svg" />
                      <img
                        className="leading-icon7"
                        alt=""
                        src="/leading-icon3@2x.png" />
                    </div>
                  </div>
                </div>
                <div className="content-grid">
                  <div className="content-rows">
                    <div className="heading-group">
                      <div className="heading2">UX UI Design</div>
                      <div className="heading3">UX UI Design</div>
                    </div>
                    <div className="text1">
                    Our UX/UI design service focuses on creating intuitive, user-friendly interfaces that enhance user engagement and experience. We ensure that every element of your application or website is designed to meet user needs while aligning with your brand identity.

                    </div>
                  </div>
                </div>
                <div className="actions3">
                  <div className="button10">
                    <div className="button11">Button</div>
                  </div>
                  <div className="button12">
                    <div className="button13">More</div>
                    <img
                      className="leading-icon4"
                      alt=""
                      src="/icon--chevronright.svg" />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="our-service1">
            <div className="section-title1">
              <div className="icon-relume1">
                <div className="wrapper1">
                  <b className="b1">1</b>
                </div>
              </div>
              <div className="content5">
                <div className="content-items">
                  <div className="ellipse-group">
                    <div className="frame-child640" />
                    <div className="frame-child641" />
                    <div className="fab-extended4">
                      <img
                        className="leading-icon8"
                        alt=""
                        src="/leading-icon.svg" />
                      <div className="add-more2">Add More</div>
                    </div>
                    <div className="fab-extended5">
                      <div className="new-item2">New Item</div>
                      <img
                        className="leading-icon4"
                        alt=""
                        src="/leading-icon2.svg" />
                    </div>
                    <img
                      className="leading-icon10"
                      alt=""
                      src="/leading-icon1@2x.png" />
                    <img
                      className="leading-icon11"
                      alt=""
                      src="/leading-icon3@2x.png" />
                    <div className="navbar-41">
                      <div className="container1">
                        <div className="content-element-wrapper">
                          <div className="content-element">
                            <div className="content6">
                              <div className="combination-mark-logo-version1">
                                <img
                                  className="symbol-logo-version-31"
                                  alt=""
                                  src="/symbol-logo-version-31@2x.png" />
                                <div className="lettermark-logo-version-31">
                                  <img
                                    className="ashdip-icon1"
                                    alt=""
                                    src="/ashdip1.svg" />
                                  <div className="solutions-container">
                                    <img
                                      className="frame-icon1"
                                      alt=""
                                      src="/frame1@2x.png" />
                                    <div className="it-solutions-wrapper">
                                      <div className="it-solutions1">
                                        IT Solutions
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="frame1" />
                            </div>
                          </div>
                        </div>
                        <div className="actions4">
                          <div className="button14">
                            <div className="lets-talk1">Website Audit</div>
                            <img
                              className="button-child1"
                              alt=""
                              src="/arrow-13.svg" />
                          </div>
                          <div className="home-icon-parent">Home
                          <img
                              className="button-child1"
                              alt=""
                              src="/arrow-13.svg" />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="content-grid">
                  <div className="content-rows">
                    <div className="heading-group">
                      <div className="heading2">Web Design & Develpopment</div>
                    </div>
                    <div className="text1">
                    We offer comprehensive web design and development services that include everything from responsive design to full-stack development. Our solutions are tailored to meet your business objectives, ensuring your website is both visually appealing and highly functional.

                    </div>
                  </div>
                </div>
                <div className="actions3">
                  <div className="button10">
                    <div className="button11">Button</div>
                  </div>
                  <div className="button12">
                    <div className="button13">More</div>
                    <img
                      className="leading-icon4"
                      alt=""
                      src="/icon--chevronright.svg" />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="our-service1">
            <div className="section-title1">
              <div className="icon-relume1">
                <div className="wrapper1">
                  <b className="b1">1</b>
                </div>
              </div>
              <div className="content7">
                <div className="content-inner3">
                  <div className="ellipse-container">
                    <div className="frame-child640" />
                    <div className="frame-child641" />
                    <div className="fab-extended6">
                      <img
                        className="leading-icon8"
                        alt=""
                        src="/leading-icon.svg" />
                      <div className="add-more2">Add More</div>
                    </div>
                    <div className="fab-extended7">
                      <div className="new-item2">New Item</div>
                      <img
                        className="leading-icon4"
                        alt=""
                        src="/leading-icon2.svg" />
                    </div>
                    <img
                      className="leading-icon10"
                      alt=""
                      src="/leading-icon1@2x.png" />
                    <img
                      className="leading-icon11"
                      alt=""
                      src="/leading-icon3@2x.png" />
                    <div className="navigation-drawer">
                      <div className="scrim" />
                      <div className="drawer">
                        <div className="header">
                          <div className="profile-details">
                            <Avatar />
                            <div className="time-selection">
                              <div className="ganesha">Ganesha</div>
                              <div className="domain-input">
                                <div className="ganeshaashdipitsolutionin">
                                  ganesha@ashdipitsolution.in
                                </div>
                                <img
                                  className="arrow-drop-down-icon"
                                  alt=""
                                  src="/arrow-drop-down.svg" />
                              </div>
                            </div>
                          </div>
                        </div>
                        <Divider />
                        <Section
                          projects="Projects"
                          pentagon="/pentagon.svg"
                          pentagon2="/pentagon.svg"
                          pentagon3="/pentagon1.svg"
                          pentagon5="/pentagon1.svg" />
                        <Divider />
                        <Section
                          projects="Subtitle"
                          pentagon="/pentagon2.svg"
                          pentagon2="/pentagon2.svg"
                          pentagon3="/pentagon.svg"
                          pentagon5="/pentagon.svg" />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="content-grid">
                  <div className="content-rows">
                    <div className="heading-group">
                      <div className="heading2">Web App Development</div>

                    </div>
                    <div className="text1">
                    We specialize in building robust and scalable mobile applications tailored to your business needs. Our development process ensures seamless user experiences and high performance across all mobile platforms.

                    </div>
                  </div>
                </div>
                <div className="actions3">
                  <div className="button10">
                    <div className="button11">Button</div>
                  </div>
                  <div className="button12">
                    <div className="button13">More</div>
                    <img
                      className="leading-icon4"
                      alt=""
                      src="/icon--chevronright.svg" />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="our-service1">
            <div className="section-title1">
              <div className="icon-relume1">
                <div className="wrapper1">
                  <b className="b1">1</b>
                </div>
              </div>
              <div className="content4">
                <div className="content-items">
                  <div className="ellipse-parent1">
                    <div className="frame-child640" />
                    <div className="frame-child641" />
                    <div className="fab-extended8">
                      <img
                        className="leading-icon8"
                        alt=""
                        src="/leading-icon.svg" />
                      <div className="add-more2">Add More</div>
                    </div>
                    <div className="fab-extended9">
                      <div className="new-item2">New Item</div>
                      <img
                        className="leading-icon4"
                        alt=""
                        src="/leading-icon2.svg" />
                    </div>
                    <img
                      className="leading-icon10"
                      alt=""
                      src="/leading-icon1@2x.png" />
                    <img
                      className="leading-icon11"
                      alt=""
                      src="/leading-icon3@2x.png" />
                    <div className="time-picker">
                      <div className="rectangle2" />
                      <div className="time-picker-inner">
                        <div className="frame-parent14">
                          <div className="frame-parent15">
                            <div className="title-parent">
                              <div className="title">
                                <div className="select-time">Select Time</div>
                              </div>
                              <div className="time-input">
                                <div className="time-input1">
                                  <div className="hour-input" />
                                  <div className="hour-placeholder">09</div>
                                  <div className="minute-input" />
                                  <div className="time-separator">
                                    <img
                                      className="separator-icon"
                                      alt=""
                                      src="/separator-icon.svg" />
                                  </div>
                                  <div className="minute-placeholder">41</div>
                                </div>
                                <div className="ampm-selector">
                                  <div className="am-pm-container" />
                                  <div className="am-button">
                                    <img
                                      className="rectangle-icon"
                                      alt=""
                                      src="/rectangle.svg" />
                                    <div className="am">AM</div>
                                  </div>
                                  <div className="pm-button">
                                    <div className="pm">PM</div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="dial-wrapper">
                              <div className="dial">
                                <div className="clock-face" />
                                <div className="clock-elements">
                                  <img
                                    className="needle-icon"
                                    alt=""
                                    src="/needle.svg" />
                                  <div className="numbers">
                                    <div className="number-value-wrapper">
                                      <div className="number-value">12</div>
                                    </div>
                                    <div className="frame-parent16">
                                      <div className="number-container-wrapper">
                                        <div className="parent">
                                          <div className="div">10</div>
                                          <div className="frame-wrapper3">
                                            <div className="wrapper5">
                                              <div className="div1">9</div>
                                            </div>
                                          </div>
                                          <div className="div2">8</div>
                                        </div>
                                      </div>
                                      <div className="group">
                                        <div className="div3">11</div>
                                        <div className="div4">7</div>
                                      </div>
                                      <div className="wrapper6">
                                        <div className="div2">6</div>
                                      </div>
                                      <div className="parent1">
                                        <div className="div6">1</div>
                                        <div className="first-number">5</div>
                                      </div>
                                      <div className="number-container-wrapper">
                                        <div className="number-container">
                                          <div className="number-pair">
                                            <div className="first-number">
                                              2
                                            </div>
                                            <div className="first-number">
                                              4
                                            </div>
                                          </div>
                                          <div className="single-number-container">
                                            <div className="first-number">
                                              3
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="profile-divider" />
                        </div>
                      </div>
                      <div className="frame2">
                        <div className="keyboard">
                          <img
                            className="keyboard-icon"
                            alt=""
                            src="/keyboard.svg" />
                        </div>
                        <div className="small-buttons">
                          <ButtonSmall
                            propWidth="unset"
                            propFlex="1"
                            button="Cancel"
                            propWidth1="unset"
                            propDisplay="unset"
                            propFlex1="1" />
                          <ButtonSmall button="OK" />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="content-grid">
                  <div className="content-rows">
                    <div className="heading-group">
                      <div className="heading2">Mobile App Development</div>
                    </div>
                    <div className="text1">
                    Our app development services cover everything from native to cross-platform app development. We build apps that are not only functional and scalable but also provide an excellent user experience on all devices.

                    </div>
                  </div>
                </div>
                <div className="actions3">
                  <div className="button10">
                    <div className="button11">Button</div>
                  </div>
                  <div className="button12">
                    <div className="button13">More</div>
                    <img
                      className="leading-icon4"
                      alt=""
                      src="/icon--chevronright.svg" />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="our-service1">
            <div className="section-title1">
              <div className="icon-relume1">
                <div className="wrapper1">
                  <b className="b1">1</b>
                </div>
              </div>
              <div className="content4">
                <div className="content-items">
                  <div className="ellipse-parent2">
                    <div className="frame-child646" />
                    <div className="frame-child647" />
                    <div className="fab-extended10">
                      <img
                        className="leading-icon8"
                        alt=""
                        src="/leading-icon.svg" />
                      <div className="add-more2">Add More</div>
                    </div>
                    <div className="fab-extended11">
                      <div className="new-item2">New Item</div>
                      <img
                        className="leading-icon4"
                        alt=""
                        src="/leading-icon2.svg" />
                    </div>
                    <img
                      className="leading-icon22"
                      alt=""
                      src="/leading-icon1@2x.png" />
                    <img
                      className="leading-icon23"
                      alt=""
                      src="/leading-icon3@2x.png" />
                    <img
                      className="leading-icon4"
                      alt=""
                      src="/icon--facebook1.svg" />
                    <img
                      className="leading-icon4"
                      alt=""
                      src="/icon--instagram1.svg" />
                    <img
                      className="leading-icon4"
                      alt=""
                      src="/icon--linkedin1.svg" />
                    <img className="leading-icon4" alt="" src="/icon--x1.svg" />
                    <img
                      className="leading-icon24"
                      alt=""
                      src="/leading-icon4@2x.png" />
                  </div>
                </div>
                <div className="content-grid">
                  <div className="content-rows">
                    <div className="heading-group">
                      <div className="heading2">Digital Marketing</div>
                    </div>
                    <div className="text1">
                    Our digital marketing services encompass SEO, social media marketing, email campaigns, and more. We work to increase your online presence, drive traffic to your site, and convert visitors into customers with targeted, effective marketing strategies.

                    </div>
                  </div>
                </div>
                <div className="actions3">
                  <div className="button10">
                    <div className="button11">Button</div>
                  </div>
                  <div className="button12">
                    <div className="button13">More</div>
                    <img
                      className="leading-icon4"
                      alt=""
                      src="/icon--chevronright.svg" />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <OurService
            serviceCardPara=" We collaborate with industry-leading partners to bring you the best in technology, innovation, and services. Our partnerships are built on trust, expertise, and a shared commitment to success."
            serviceCardHeading="Partner" />
        </div>
      </div>
    </section>
    </>
  );
};

ServiceContainer.propTypes = {
  className: PropTypes.string,
};

export default ServiceContainer;
