import Navbar from "../components/navbar";
import FrameComponent from "../components/frame-component";
import ServiceContainer from "../components/service-container";
import FrameComponent2 from "../components/ourprocess";
import TestimonialRows from "../components/testimonial-rows";
import Contactus from "../components/contactus";
import FrameComponent3 from "../components/faq-questions";
import CardContainer from "../components/card-container";
import "./desktop1280x.css";

const Desktop1280x = () => {
  return (
    <div className="desktop-1280x800">
       <Navbar />
      <FrameComponent />
      <div className="frame-parent3">
        <div className="frame-parent4">
          <div className="ith-our-parent">
            <div className="ith-our">{`ith our `}</div>
            <i className="expertize">Expertize</i>
          </div>
          <img className="union-icon" alt="" />
          <img className="dicons" alt="" src="/3dicons2@2x.png" />
        </div>
        <div className="dicons-parent">
          <img className="dicons1" alt="" src="/3dicons3@2x.png" />
          <div className="ashdip">Ashdip</div>
          <div className="here">Here</div>
          <div className="team">Team</div>
          <div className="ashdip1">Ashdip</div>
          <div className="ashdip2">Ashdip</div>
          <div className="ashdip3">Ashdip</div>
          <img className="group-icon" alt="" src="/group-6.svg" />
        </div>
      </div>
      <div className="offer-wrapper">
        <b className="offer">@ Offer</b>
      </div>
      <ServiceContainer />
      <FrameComponent2 />
      <div className="testimonial">
        <div className="testimonial-grid">
          <TestimonialRows />
          <Contactus />
        </div>
      </div>
      <FrameComponent3 />
      <CardContainer />
    </div>
  );
};

export default Desktop1280x;
