import React from 'react'
import ShowProjectInquiries from '../components/admin/showProjectInquirie/ShowProjectInquiries'

const ShowProjectInquiriesPage = () => {
    return (
        <div>
            <ShowProjectInquiries />
        </div>
    )
}

export default ShowProjectInquiriesPage