import React from 'react';
import { NavLink } from 'react-router-dom';

const Navbar = () => {
    return (
        <nav className="bg-white shadow-md">
            <div className="container mx-auto flex justify-between items-center p-3 sticky top-0">
                {/* logo */}
                <div className="flex items-center space-x-6 col-sm-12 col-md-6">
                    <div className="text-dark ">
                        <span className="flex items-center">
                            <i className="fa-solid fa-phone mx-1"></i>
                            9309234245
                        </span>
                    </div>
                    <div className="text-dark">
                        <span className="flex items-center">

                            <i className="fa-solid fa-location-dot mx-1"></i>
                            PIMPRI, PUNE
                        </span>
                    </div>
                    <div className="text-dark">
                        <span className="flex items-center">
                            <i className="fa-solid fa-envelope mx-1"></i>
                            info@ashdipitsolutions.in
                        </span>
                    </div>
                </div>

                {/* Navlink */}
                <div className=" space-x-8 col-sm-12 col-md-6 text-end">
                    <NavLink to="/" className="text-black hover:text-primary fw-bold">
                        Home
                    </NavLink>
                    <NavLink to="/career" className="text-black hover:text-primary fw-bold">
                        Career
                    </NavLink>
                    <NavLink to="/#contactussection" className="text-black hover:text-primary fw-bold">
                        Contact Us
                    </NavLink>
                </div>


            </div>
        </nav>

    )
}

export default Navbar