import { useState, useMemo } from "react";
import PropTypes from "prop-types";
import "./accordion-item.css";

const AccordionItem = ({ className = "", propPadding, question, answer }) => {
  const [isOpen, setIsOpen] = useState(false);

  const accordionItemStyle = useMemo(() => {
    return {
      padding: propPadding,
    };
  }, [propPadding]);

  const toggleAnswer = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div className={`accordion-item ${className}`} style={accordionItemStyle}>
      <div className="question" onClick={toggleAnswer} style={{ cursor: "pointer" }}>
        <b className="question1">{question}</b>
        <img 
          className="icon5" 
          alt={isOpen ?  "Expand" : "Collapse"} 
          src={isOpen ?  "/icon-expand.svg" : "/icon-collapse.svg"} 
        />
      </div>
      {isOpen && (
        <div className="answer">
          <div className="text7">
            {answer}
          </div>
        </div>
      )}
    </div>
  );
};

AccordionItem.propTypes = {
  className: PropTypes.string,
  propPadding: PropTypes.any,
  question: PropTypes.string.isRequired,
  answer: PropTypes.string.isRequired,
};

export default AccordionItem;
