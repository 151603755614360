import React, { useState } from "react";
import PropTypes from "prop-types";
import InquiryFormModal from "./inquiry"; // Import the InquiryFormModal component
import "./testimonial-rows.css";

const TestimonialRows = ({ className = "" }) => {
  // State to manage modal visibility
  const [modalShow, setModalShow] = useState(false);

  // Functions to handle opening and closing of the modal
  const handleModalOpen = () => setModalShow(true);
  const handleModalClose = () => setModalShow(false);

  return (
    <div className={`testimonial-rows ${className}`}>
      <div className="testimonial-items2">
        <div className="h2">
          <span className="meet-our">{`Meet Our `}</span>
          <span className="extraordinary">{`extraordinary `}</span>
          <span className="multi">{`       & Multi-`}</span>
          <span className="disciplinary">{`Disciplinary `}</span>Team
        </div>
        <img
          className="ketharicircle-icon"
          alt=""
          src="/ketharicircle@2x.png"
        />
      </div>

      <div className="testimonial-items4">
        <img
          className="sadn-dipcircle-icon"
          alt=""
          src="/sadndipcircle@2x.png"
        />
      </div>

      <div className="testimonial-items9">
        <div className="devices-macbook-pro-parent">
          <img
            className="devices-macbook-pro"
            alt=""
            src="/devices--macbook-pro@2x.png"
          />
          <div className="h21">
            <p className="simply-who-get">
              <span className="simply">Simply</span>
              <span className="who-get"> WHO GET</span>
            </p>
            <p className="job">     JOB</p>
            <p className="job">{`          DONE 🤝🏻 `}</p>

            {/* Add the modal trigger here */}
            <button className="project-inquiry-btn" onClick={handleModalOpen}>
              Project Inquiry
            </button>
          </div>
        </div>
        <img className="ankitacircle-icon" alt="" src="/ankitacircle@2x.png" />
      </div>
      <div className="testimonial-items14">
        <div className="ellipse-parent3">
          <div className="frame-child652" />
          <img className="ashok-icon" alt="" src="/ashok@2x.png" />
        </div>
      </div>

      {/* Render the InquiryFormModal */}
      <InquiryFormModal show={modalShow} handleClose={handleModalClose} />
    </div>
  );
};

TestimonialRows.propTypes = {
  className: PropTypes.string,
};

export default TestimonialRows;
