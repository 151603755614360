import React from 'react'
import ApplyNow from '../components/apply/ApplyNow'

const ApplyNowPage = () => {
    return (
        <div>
            <ApplyNow />
        </div>
    )
}

export default ApplyNowPage