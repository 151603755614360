import React, { useState } from "react";
import { Link as ScrollLink } from 'react-scroll';
import Swal from 'sweetalert2';
import PropTypes from "prop-types";
import axiosInstance from "../services/AxiosInstance"; // Import the Axios instance
import "./contactus.css";
import 'bootstrap/dist/css/bootstrap.min.css';
import InquiryFormModal from "./inquiry";

const Contactus = ({ className = "" }) => {
  const [modalShow, setModalShow] = useState(false);
  const [formData, setFormData] = useState({
    contactname: "",
    contactphone: "",
    contactemail: "",
    contactmessage: "",
  });
  const [errors, setErrors] = useState({});

  const handleModalOpen = () => setModalShow(true);
  const handleModalClose = () => setModalShow(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const validate = () => {
    const newErrors = {};
    if (!formData.contactname) {
      newErrors.contactname = "--- Name is required";
    }
    if (!formData.contactphone) {
      newErrors.contactphone = "--- Mobile number is required";
    } else if (!/^\d{10}$/.test(formData.contactphone)) {
      newErrors.contactphone = "--- Please enter a valid 10-digit mobile number.";
    }
    if (formData.contactemail && !/\S+@\S+\.\S+/.test(formData.contactemail)) {
      newErrors.contactemail = "--- Please enter a valid email address.";
    }
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (validate()) {
      try {
        debugger
        await axiosInstance.post("/backend/savecontact", formData); // Use relative path
        // Show success message
        //alert("Form submitted successfully! We will contact you soon...");
         // Show SweetAlert success message
      Swal.fire({
        title: 'Success!',
        text: 'Form submitted successfully! We will contact you soon...!',
        icon: 'success',
        confirmButtonText: 'OK'
      });

        // Clear form fields
        setFormData({
          contactname: "",
          contactphone: "",
          contactemail: "",
          contactmessage: "",
        });
      } catch (error) {
        // Handle submission errors (e.g., show an error message)
        console.error("Form submission error:", error);
        //alert("There was an error submitting the form. Please try again.");

              // Show SweetAlert error message
      Swal.fire({
        title: 'Error!',
        text: 'There was an error submitting the form. Please try again.',
        icon: 'error',
        confirmButtonText: 'OK'
      });
      
      }
    } else {
      console.log("Validation errors:", errors);
    }
  };

  return (
    <div id="contactussection" className={`contact-modal-3-wrapper ${className}`}>
      <div className="contact-modal-3" id="contactusform">
        <img className="icon4" alt="Contact Icon" src="/icon.svg" />
        <div className="heading-content-wrapper">
          <div className="heading-content">
            <h2 className="heading13">Contact Us</h2>
            <p className="contact-description">
              We would love to hear from you. Please fill out the form below, and we will get in touch with you shortly.
            </p>
          </div>
          <img className="contactusicon" alt="" src="/contactus3dicon.png" />
        </div>
        <form className="input-fields" onSubmit={handleSubmit}>
          <div className="name-email">
            <label className="name form-label" htmlFor="contactname">
              Name {errors.contactname && <span className="text-danger contacterror">{errors.contactname}</span>}
            </label>
            <input
              id="contactname"
              name="contactname"
              type="text"
              value={formData.contactname}
              onChange={handleChange}
              className="text-input form-control"
            />
          </div>
          <div className="name-email">
            <label className="name form-label" htmlFor="contactphone">
              Mobile No {errors.contactphone && <span className="text-danger contacterror">{errors.contactphone}</span>}
            </label>
            <input
              id="contactphone"
              name="contactphone"
              type="text"
              value={formData.contactphone}
              onChange={handleChange}
              className="text-input form-control"
            />
          </div>
          <div className="name-email">
            <label className="name form-label" htmlFor="contactemail">
              Email
            </label>
            {errors.contactemail && <div className="text-danger contacterror">{errors.contactemail}</div>}
            <input
              id="contactemail"
              name="contactemail"
              type="email"
              value={formData.contactemail}
              onChange={handleChange}
              className="text-input form-control"
            />
          </div>
          <div className="message-field">
            <label className="message form-label" htmlFor="contactmessage">
              Message
            </label>
            <textarea
              id="contactmessage"
              name="contactmessage"
              value={formData.contactmessage}
              onChange={handleChange}
              className="text-area form-control"
            />
          </div>
          <div className="buttons">
            <button type="submit" className="button35 reach-us">
              Reach Us
            </button>
            <ScrollLink to="contactussection" smooth={true} duration={50} className="button36" onClick={handleModalOpen} style={{cursor:"pointer"}}>
              <div className="project-enquiry1">Project Inquiry</div>
              <img className="button-child3" alt="" src="/arrow-1.svg" />
            </ScrollLink>
          </div>
        </form>
      </div>
      <InquiryFormModal show={modalShow} handleClose={handleModalClose} />
    </div>
  );
};

Contactus.propTypes = {
  className: PropTypes.string,
};

export default Contactus;
