import React from 'react'
import CodingTestInstruction from '../components/codingTestInstruction/CodingTestInstruction'

const CodingTestInstructionPage = () => {
  return (
    <div>
      <CodingTestInstruction />
    </div>
  )
}

export default CodingTestInstructionPage