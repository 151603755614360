import PropTypes from "prop-types";
import "./avatar.css";

const Avatar = ({ className = "" }) => {
  return (
    <div className={`avatar ${className}`}>
      <div className="profile-image" />
      <div className="mk">G</div>
    </div>
  );
};

Avatar.propTypes = {
  className: PropTypes.string,
};

export default Avatar;
