import React, { useEffect } from 'react'
import { Link, useLocation, useNavigate } from 'react-router-dom';


const McqTest = () => {


    const location = useLocation();
    const testId = location.state?.id;
    const jobTitle = location.state?.title;
    const navigate = useNavigate();

    useEffect(() => {
        // Check if testId and jobTitle are null, and navigate to /career if true
        if (testId == null && jobTitle == null) {
            navigate("/career");
        }
    }, [testId, jobTitle, navigate ]); // Dependencies to re-run the effect if these values change




    const handleStartTest = () => {
        // console.log("Test started");

        setTimeout(() => {
            navigate("/mcq-test", { state: { id: testId, title: jobTitle } })
        },  );
        // Redirect to the test page or start the test logic here
    };




    return (
        <div className=" flex items-center justify-center py-10 bg-gray-200">
            <div className="max-w-4xl w-full bg-white shadow-xl rounded-lg p-10">
                <h1 className="text-4xl font-bold text-center text-gray-900 mb-8">
                    Instructions
                </h1>
                {/* <p>career id {testId}</p>
                <p>job apply  {jobTitle}</p> */}
                <div className="bg-yellow-100 p-6 rounded-lg mb-6 border-l-4 border-yellow-500">
                    <h2 className="text-3xl font-semibold text-yellow-800 mb-4 text-center ">
                        Read Carefully
                    </h2>
                    <ul className="list-decimal list-inside text-gray-700 space-y-2">
                        <li><span>Format:</span>The test consists of 20 multiple-choice questions (MCQs).</li>
                        <li><span>Passing Score:</span>A minimum of 60% is required for selection.</li>
                        <li><span>Time Limit:</span>The test must be completed within 20 minutes.</li>
                        <li><span>Camera Acess</span>Allow the Camera acess from settings..Before start test..</li>
                        <li><span>Warning:</span>Don't change the tab when the exam will start. Otherwise, the exam will be submitted.

                        </li>
                    </ul>
                    <p className="text-center mt-2 text-green-700">All the best!</p>
                </div>
                <div className="flex justify-center mt-6">
                    <Link onClick={handleStartTest}

                        className="bg-gradient-to-r from-green-500 to-teal-400 text-white text-lg py-3 px-8 rounded-full shadow-lg hover:from-green-600 hover:to-teal-500 transform hover:scale-105 transition-transform duration-300"
                    >
                        Start Test
                    </Link>
                </div>
            </div>
        </div>
    )
}

export default McqTest