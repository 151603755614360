import { useMemo } from "react";
import PropTypes from "prop-types";
import "./button-small.css";

const ButtonSmall = ({
  className = "",
  propWidth,
  propFlex,
  button,
  propWidth1,
  propDisplay,
  propFlex1,
}) => {
  const buttonSmallStyle = useMemo(() => {
    return {
      width: propWidth,
      flex: propFlex,
    };
  }, [propWidth, propFlex]);

  const buttonStyle = useMemo(() => {
    return {
      width: propWidth1,
      display: propDisplay,
      flex: propFlex1,
    };
  }, [propWidth1, propDisplay, propFlex1]);

  return (
    <div className={`button-small ${className}`} style={buttonSmallStyle}>
      <div className="second-wrapper">
        <div className="button5" style={buttonStyle}>
          {button}
        </div>
      </div>
      <div className="min-width-64" />
    </div>
  );
};

ButtonSmall.propTypes = {
  className: PropTypes.string,
  button: PropTypes.string,

  /** Style props */
  propWidth: PropTypes.any,
  propFlex: PropTypes.any,
  propWidth1: PropTypes.any,
  propDisplay: PropTypes.any,
  propFlex1: PropTypes.any,
};

export default ButtonSmall;
