import React, { useState } from 'react';
import { Link } from 'react-router-dom';

const jobData = {
    job1: {
        title: 'Java Full Stack Developer',
        subTitle: 'java',
        responsibilities: [
            'Develop and maintain high-quality Java-based applications.',
            'Write efficient, reusable, and reliable code.',
            'Understand and implement security best practices.',
            'Ensure development of secure applications protecting against common vulnerabilities.',
            'Collaborate with team members.',
        ],
        skills: [
            'Core Java, Spring, Spring Boot, JDBC, HTML, CSS, JavaScript, React JS, PostgreSQL.',
        ],
    },
    job2: {
        title: 'Digital Marketing',
        subTitle: 'dm',
        responsibilities: [
            'Develop marketing strategies.',
            'Manage social media campaigns.',
            'Optimize content for search engines (SEO).',
            'Content creation and management.',
            'Analyze marketing metrics and report on performance.',
        ],
        skills: [
            'Experience in digital marketing.',
            'Strong analytical skills.',
            'Content creation.',
            'Email marketing.',
        ],
    },
    job3: {
        title: 'UX/UI Designer',
        subTitle: 'uiux',
        responsibilities: [
            'Design user interfaces for web and mobile applications.',
            'Create wireframes, prototypes, and mockups using Figma.',
            'Conduct user research and gather feedback.',
            'Collaborate with developers and product managers.',
            'Maintain and improve the design system and UI/UX best practices.',
        ],
        skills: [
            'Proficiency in Figma, Adobe XD, Sketch, or similar.',
            'Strong portfolio showcasing UI/UX skills.',
            'Basic understanding of HTML/CSS.',
            'Responsive design principles.',
        ],
    },
    // job4: {
    //     title: 'ReactJs Intern',
    //     responsibilities: [
    //         'Write efficient, reusable, and reliable code.',
    //         'Understand and implement security best practices.',
    //         'Participate in code reviews and provide feedback.',
    //         'Assist in developing web applications using React.js.',
    //         'Collaborate with team members on project tasks.',
    //     ],
    //     skills: [
    //         'Familiarity with JavaScript, HTML, CSS, Bootstrap, React.js.',
    //         'Basic understanding of Node.js and Express.js.',
    //         'Strong problem-solving skills.',
    //         'Ability to work collaboratively in a team environment.',
    //     ],
    // },
    // job5: {
    //     title: 'Fullstack Developer',
    //     responsibilities: [
    //         'Write efficient, reusable, and reliable code.',
    //         'Understand and implement security best practices.',
    //         'Ensure secure application development.',
    //         'Participate in code reviews and provide feedback.',
    //         'Develop and maintain web applications using React.js.',
    //     ],
    //     skills: [
    //         'Familiarity with JavaScript, HTML, CSS, Bootstrap, React.js.',
    //         'Basic understanding of Node.js and Express.js.',
    //         'Strong problem-solving skills.',
    //         'Ability to work collaboratively in a team environment.',
    //     ],
    // },
    job6: {
        title: 'React Native Developer',
        responsibilities: [
            'Write efficient, reusable, and reliable code.',
            'Implement security best practices.',
            'Ensure secure application development.',
            'Participate in code reviews and provide feedback.',
            'Develop and maintain mobile applications using React Native.',
        ],
        skills: [
            'Familiarity with JavaScript, HTML, CSS, Bootstrap, React Native.',
            'Basic understanding of mobile development principles.',
            'Strong problem-solving skills.',
            'Ability to work collaboratively in a team environment.',
        ],
    },
    job7: {
        title: 'Python Developer',
        responsibilities: [
            'Write efficient, reusable, and reliable code.',
            'Understand and implement security best practices.',
            'Ensure secure application development.',
            'Participate in code reviews and provide feedback.',
            'Develop and maintain applications using Python.',
        ],
        skills: [
            'Familiarity with Python, Django, Flask.',
            'Basic understanding of front-end technologies.',
            'Strong problem-solving skills.',
            'Ability to work collaboratively in a team environment.',
        ],
    },
    job8: {
        title: 'Android Developer',
        responsibilities: [
            'Write efficient, reusable, and reliable code.',
            'Implement security best practices.',
            'Ensure secure application development.',
            'Participate in code reviews and provide feedback.',
            'Develop and maintain mobile applications using Android SDK.',
        ],
        skills: [
            'Familiarity with Java, Kotlin, Android SDK.',
            'Basic understanding of XML and UI/UX design principles.',
            'Strong problem-solving skills.',
            'Ability to work collaboratively in a team environment.',
        ],
    },
};

const Career = () => {
    const [selectedJob, setSelectedJob] = useState(null);

    const openModal = (jobKey) => {
        setSelectedJob(jobData[jobKey]);
    };

    const closeModal = () => {
        setSelectedJob(null);
    };

    return (
        <>
            <div className="mx-3 my-2 rounded-lg bg-gradient-to-r from-blue-500 to-purple-600 text-white py-10 text-center">
                <h1 className="text-5xl font-extrabold tracking-tight uppercase mb-4">
                    BUILD YOUR STRONG CAREER
                </h1>
                <p className="text-lg font-medium italic">
                    Choose a job you love, and you will never have to work a day in your life
                </p>
            </div>

            {/* Job Opening Section */}
            <div className="bg-gray-100 py-9 text-center pt-2" style={{paddingBottom:'30px'}}>
                <h2 className="text-3xl font-semibold mb-8">Current Job Openings</h2>
                <div className="grid grid-cols-1  sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-3 gap-5 md:mx-16 justify-center">
                    {Object.keys(jobData).slice(0, 3).map((jobKey) => (
                        <button
                            key={jobKey}
                            onClick={() => openModal(jobKey)}
                            className=" mx-2 text-white py-3 px-6 rounded-lg shadow-md hover:bg-blue-600  "
                            style={{ backgroundColor: 'darkblue' }}
                        >
                            {jobData[jobKey].title}
                        </button>
                    ))}
                    {Object.keys(jobData).slice(3).map((jobKey) => (
                        <button
                            key={jobKey}
                            className="bg-blue-500 mx-2 text-white py-3 px-6 rounded-lg shadow-md cursor-not-allowed"
                            
                            disabled
                        >
                            {jobData[jobKey].title} <i className="fa-solid fa-lock"></i>
                        </button>
                    ))}
                </div>
            </div>

            {/* Modal */}
            {selectedJob && (
                <div className="fixed inset-0 bg-gray-900 bg-opacity-50 flex justify-center items-center">
                    <div className="bg-white rounded-lg p-8 max-w-lg mx-auto relative">
                        <div className="flex justify-between items-center mb-4">
                            <h3 className="text-2xl font-bold">{selectedJob.title}</h3>
                            <button onClick={closeModal} className="text-2xl text-rose-600">
                                &times;
                            </button>
                        </div>
                        <hr className="border-t border-blue-500 my-4" />
                        <div className="mb-4">
                            <strong>Responsibilities:</strong>
                            <ul className="list-disc pl-5 mt-2">
                                {selectedJob.responsibilities.map((responsibility, index) => (
                                    <li key={index} className="text-left mb-2">{responsibility}</li>
                                ))}
                            </ul>
                        </div>
                        <div className="mb-4">
                            <strong>Skills Required:</strong>
                            <ul className="list-disc pl-5 mt-2">
                                {selectedJob.skills.map((skill, index) => (
                                    <li key={index} className="text-left mb-2">{skill}</li>
                                ))}
                            </ul>
                        </div>
                        <hr className="border-t border-blue-500 my-4" />
                        <div className="flex justify-center">
                            <Link
                                to={`/apply/${encodeURIComponent(selectedJob.subTitle)}`}
                                className="bg-green-400 text-white py-2 px-4 rounded-lg hover:bg-green-500"
                            >
                                Apply
                            </Link>
                        </div>
                    </div>
                </div>
            )}
        </>
    );
};

export default Career;
