import React from 'react'
import McqTest from '../components/mcqTest/McqTest'

const McqTestPage = () => {
    return (
        <div>
            <McqTest />
        </div>
    )
}

export default McqTestPage 