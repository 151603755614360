import React, { useState } from "react";
import { Link } from 'react-router-dom';
import { Link as ScrollLink } from 'react-scroll';
import PropTypes from "prop-types";
import "./navbar.css";

const Navbar = ({ className = "" }) => {
  const [isExpanded, setIsExpanded] = useState(false);

  const toggleNavbar = () => {
    setIsExpanded(!isExpanded);
  };


  const whatsappNumber = "+91 93092 34245"; // Replace with your desired WhatsApp number
  const whatsappMessage = "Hello, I would like to know more about your services."; // Your predefined message

  return (
    <div className={`navbar-4 ${className}`}>
      <div className="logo-wrapper-parent">
        <div className="logo-container-parent">
          <div className="logo-container">
            <div className="content">
              <div className="combination-mark-logo-version">
                <div className="symbol-logo">
                  <img
                    className="symbol-logo-version-3"
                    alt="logo"
                    src="/symbol-logo-version-3@2x.png"
                  />
                </div>
                <div className="lettermark-logo-version-3">
                  <img className="ashdip-icon" alt="" src="/ashdip.svg" />
                  <img className="frame-icon" alt="" src="/frame@2x.png" />
                  <div className="it-solutions">IT Solutions</div>
                </div>
              </div>
              <div className="frame" />
            </div>
          </div>
          <a className="button1" href="/">
            <div className="lets-talk">Free Website Audit</div>
            <img className="button-child" alt="" src="/arrow-1.svg" />
          </a>
        </div>
        <div className="frame-wrapper">
          <button
            className="home-icon-parent"
            onClick={toggleNavbar}
            aria-expanded={isExpanded}
          >
            <div className="home-icon">
              <div className="home">Home</div>
            </div>
            <img
              className="icon-chevron-down"
              alt="Toggle Navbar"
              src={isExpanded ? "/icon--chevrondown1.svg" : "/icon--chevrondown.svg"}
            />
          </button>
        </div>
      </div>

      {isExpanded && (
        <div className="services-items-wrapper">
          <div className="services-items">
            <div className="services-list">
              <div className="our-services">
                <Link to="/adminlogin" style={{ textDecoration: 'none', color: 'white' }} >Admin</Link>
              </div>
              <img className="services-list-child" alt="" />
              <div className="our-services">
              <Link to="/career" style={{ textDecoration: 'none', color: 'white' }} >Career</Link>
              </div>
              <img className="services-list-child" alt="" />

               {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
               <ScrollLink to="servicesection" smooth={true} duration={50} className="our-services">Our Services</ScrollLink>
              <img className="services-list-child" alt="" />
              <a href="/" className="our-services">About</a>
              <img className="services-list-child" alt="" />
              <a href="/" className="our-services">Blog</a>
              <img className="services-list-child" alt="" />

              <div className="social-links-container">
                <div className="our-services">Social Links</div>
                <div className="social-links1">
                  <a href="https://www.facebook.com/share/Gdo7TDLddQnY8tAF/?mibextid=qi2Omg" target="_blank" rel="noopener noreferrer">
                    <img className="icon-facebook" alt="Facebook" src="/icon--facebook.svg" />
                  </a>
                  <a href="https://www.instagram.com/ashdipit?igsh=MWV3cmZpbGc5cGRwYw==" target="_blank" rel="noopener noreferrer">
                    <img className="icon-facebook" alt="Instagram" src="/icon--instagram.svg" />
                  </a>
                  <a href="https://x.com/Ashdipit?s=09" target="_blank" rel="noopener noreferrer">
                    <img className="icon-facebook" alt="X" src="/icon--x.svg" />
                  </a>
                  <a href="https://www.linkedin.com/in/ashok-sonawane-81a693183?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=ios_app" target="_blank" rel="noopener noreferrer">
                    <img className="icon-facebook" alt="LinkedIn" src="/icon--linkedin.svg" />
                  </a>
                  <a href="/" target="_blank" rel="noopener noreferrer">
                    <img className="icon-facebook" alt="YouTube" src="/icon--youtube1.svg" />
                  </a>
                  <a
                    href={`https://wa.me/${whatsappNumber}?text=${encodeURIComponent(whatsappMessage)}`}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img
                      className="icon-whatsapp"
                      alt="WhatsApp"
                      src="/icon--whatsapp.svg"
                      style={{ filter: "invert(1)" }}
                    />
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

Navbar.propTypes = {
  className: PropTypes.string,
};

export default Navbar;
