import React, {  useEffect, useState } from 'react';
import axiosInstance from '../../../services/AxiosInstance';
import { useNavigate } from 'react-router-dom';
import {isLoggedIn} from "../../../auth";

const AddCodingForm = () => {

    const navigate = useNavigate();

    useEffect(() => {
        if (!isLoggedIn()) {
            navigate("/adminlogin");
        }

    }, [navigate])

   

    const [question, setQuestion] = useState('');
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [success, setSuccess] = useState(false);

    const handleChange = (e) => {
        setQuestion(e.target.value);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);
        setError(null);
        setSuccess(false);

        console.log(question);

        try {
            // Wrap the question in an object
            const response = await axiosInstance.post('/backend/coding/question', { question });
            if (response.status === 200 || response.status === 201) {
                setSuccess(true);
                setQuestion('');
            } else {
                setError('Failed to submit the form.');
            }
        } catch (err) {
            console.error('Error details:', err.response ? err.response.data : err.message);
            setError('An error occurred while submitting the form.');
        } finally {
            setLoading(false);
        }
    };

    return (
        <div className="max-w-xl mx-auto p-4 bg-white shadow-lg rounded-lg mt-10">
            <h2 className="text-2xl font-bold mb-6 text-center">Add Coding Question</h2>
            <form onSubmit={handleSubmit}>
                <div className="mb-4">
                    <label className="block text-gray-700 font-semibold mb-2">Coding Question</label>
                    <textarea
                        name="question"
                        value={question}
                        onChange={handleChange}
                        className="w-full p-3 border border-gray-300 rounded-lg resize-none"
                        rows="4"
                        placeholder="Enter the coding question here"
                        required
                    />
                </div>
                <div className="text-center">
                    <button
                        type="submit"
                        className="bg-blue-500 text-white font-semibold py-2 px-4 rounded-lg hover:bg-blue-600 transition duration-300"
                        disabled={loading}
                    >
                        {loading ? 'Submitting...' : 'Submit'}
                    </button>
                </div>
            </form>

            {success && (
                <div className="mt-6 p-4 bg-green-100 text-green-700 rounded-lg">
                    Coding question successfully added!
                </div>
            )}

            {error && (
                <div className="mt-6 p-4 bg-red-100 text-red-700 rounded-lg">
                    {error}
                </div>
            )}
        </div>
    );
};

export default AddCodingForm;
