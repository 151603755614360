import React from 'react'
import ShowContactInquiries from '../components/admin/showContactInquirie/ShowContactInquiries'

const ContactInquiriesPage = () => {
    return (
        <div>
            <ShowContactInquiries />
        </div>
    )
}

export default ContactInquiriesPage