import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import axiosInstance from '../../services/AxiosInstance';

const CodingTestQuestion = () => {
    const navigate = useNavigate();
    // eslint-disable-next-line
    const [testId, setTestId] = useState(localStorage.getItem('testId') || null);
    // eslint-disable-next-line
    const [jobTitle, setJobTitle] = useState(localStorage.getItem('jobTitle') || null);
    const warningCountRef = useRef(0);

    const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
    const [questions, setQuestions] = useState([]);
    const [answers, setAnswers] = useState([]);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [loading, setLoading] = useState(true);
    const [errorMessage, setErrorMessage] = useState('');
    const [timer, setTimer] = useState(60 * 60); // Timer set for 2 minutes
    const [loadingButton, setLoadingButton] = useState(false); // State for loading button

    useEffect(() => {
        const handleVisibilityChange = () => {
            if (document.visibilityState === 'hidden') {
                warningCountRef.current += 1;
                if (warningCountRef.current === 1) {
                    alert('Warning: Please do not switch tabs during the test.');
                } else if (warningCountRef.current === 2) {
                    alert('Warning: Last warning. If you change tabs again, your test will not be submitted, and you will be redirected to the homepage.');
                } else if (warningCountRef.current >= 3) {
                    alert('Redirecting to the homepage due to tab switching.');
                    navigate('/career');
                }
            }
        };

        document.addEventListener('visibilitychange', handleVisibilityChange);
        return () => {
            document.removeEventListener('visibilitychange', handleVisibilityChange);
        };
    }, [navigate]);

    useEffect(() => {
        localStorage.removeItem('testId');
        localStorage.removeItem('jobTitle');
        if (testId == null || jobTitle == null) {
            navigate("/career");
        }
    }, [testId, jobTitle, navigate]);

    useEffect(() => {
        axiosInstance.get("/backend/coding/question")
            .then(response => {
                const allQuestions = response.data.map(q => ({
                    id: q.id,
                    question: q.question,
                }));
                const shuffledQuestions = allQuestions.sort(() => 0.5 - Math.random());
                const selectedQuestions = shuffledQuestions.slice(0, 5);
                setQuestions(selectedQuestions);
                setAnswers(Array(selectedQuestions.length).fill(""));
                setLoading(false);
            })
            .catch(error => {
                setErrorMessage('Failed to load questions. Please try again.');
                setLoading(false);
            });
    }, []);


    useEffect(() => {
        if (timer > 0) {
            const intervalId = setInterval(() => {
                setTimer(prev => prev - 1);
            }, 1000);
            return () => clearInterval(intervalId);
        } else if (timer === 0) {
            // eslint-disable-next-line
            confirmSubmit() // Auto-submit when timer reaches 0
        }
        // eslint-disable-next-line
    }, [timer]);

    

    const handleInputChange = (e) => {
        const newAnswers = [...answers];
        newAnswers[currentQuestionIndex] = e.target.value;
        setAnswers(newAnswers);
    };

    const handleNext = () => {
        if (!answers[currentQuestionIndex]) {
            alert("Please fill in the answer before moving to the next question.");
            return;
        }
        setLoadingButton(true); // Start loading
        setTimeout(() => {
            setCurrentQuestionIndex(prev => prev + 1);
            setLoadingButton(false); // Stop loading after transition
        }, 500); // Simulate loading for 0.5s
    };

    const handleSubmit = () => {
        if (answers.includes("")) {
            alert("Please fill in all the answers before submitting.");
            return;
        }
        setIsModalOpen(true);
    };

    const confirmSubmit = async () => {
        setLoadingButton(true); // Start loading
        const dataToSubmit = questions.map((question, index) => ({
            question: question.question,
            userCode: answers[index],
        }));

        try {
            await axiosInstance.post(`/backend/coding/answers/${testId}`, dataToSubmit);
            navigate('/upload-document', { state: { id: testId, title: jobTitle } });
        } catch (error) {
            setErrorMessage('Failed to submit the test. Please try again.');
        } finally {
            setLoadingButton(false); // Stop loading after submission
        }
    };

    const closeModal = () => {
        setIsModalOpen(false);
    };

    const formatTime = (seconds) => {
        const minutes = Math.floor(seconds / 60);
        const remainingSeconds = seconds % 60;
        return `${minutes}:${remainingSeconds < 10 ? '0' : ''}${remainingSeconds}`;
    };

    const currentQuestion = questions[currentQuestionIndex];

    return (
        <div className="max-w-3xl m-5 mx-auto p-6 bg-white shadow-xl rounded-lg border border-gray-200">
            {loading ? (
                <div className="text-center">
                    <p className="text-lg font-medium text-gray-600">Loading questions...</p>
                </div>
            ) : errorMessage ? (
                <div className="text-center">
                    <p className="text-lg font-medium text-red-600">{errorMessage}</p>
                </div>
            ) : (
                <>
                    <div className="flex justify-between items-center mb-6 row">
                        <h1 className="text-3xl font-extrabold text-gray-800 col-md-6 col-sm-12">
                            Question {currentQuestionIndex + 1} of {questions.length}
                        </h1>
                        <div className="text-2xl font-semibold text-red-600 col-md-6 col-sm-12">
                            Time Remaining: {formatTime(timer)}
                        </div>
                    </div>
                    <div className="mb-8">
                        <h2 className="text-1xl font-semibold mb-4 text-gray-700">{currentQuestion.question}</h2>
                        <textarea
                            autoFocus
                            value={answers[currentQuestionIndex]}
                            onChange={handleInputChange}
                            placeholder="Type your code here..."
                            rows="10"
                            required
                            className="w-full mt-4 p-3 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-indigo-500 whitespace-pre"
                        />
                    </div>
                    <div className="flex justify-between items-center">
                        {currentQuestionIndex < questions.length - 1 ? (
                            <button
                                onClick={handleNext}
                                disabled={loadingButton} // Disable button while loading
                                className={`bg-blue-600 text-white py-2 px-6 rounded-lg shadow-lg hover:bg-blue-700 transition duration-300 ease-in-out ${loadingButton ? 'opacity-50 cursor-not-allowed' : ''}`}
                            >
                                {loadingButton ? 'Loading...' : 'Next'}
                            </button>
                        ) : (
                            <button
                                onClick={handleSubmit}
                                disabled={loadingButton} // Disable button while loading
                                className={`bg-green-600 text-white py-2 px-6 rounded-lg shadow-lg hover:bg-green-700 transition duration-300 ease-in-out ${loadingButton ? 'opacity-50 cursor-not-allowed' : ''}`}
                            >
                                {loadingButton ? 'Loading...' : 'Submit'}
                            </button>
                        )}
                    </div>
                </>
            )}

            {isModalOpen && (
                <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
                    <div className="bg-white rounded-lg p-6 max-w-sm w-full">
                        <h2 className="text-2xl font-bold mb-4 text-gray-800">Submit Your Test</h2>
                        <p className="mb-4 text-gray-600">Are you sure you want to submit your test?</p>
                        {errorMessage && <p className="text-red-500 mb-4">{errorMessage}</p>}
                        <div className="flex justify-end space-x-4">
                            <button
                                onClick={closeModal}
                                className="px-4 py-2 bg-gray-300 text-gray-700 rounded-lg hover:bg-gray-400"
                            >
                                Cancel
                            </button>
                            <button
                                onClick={confirmSubmit}
                                disabled={loadingButton} // Disable button while loading
                                className={`px-4 py-2 bg-green-600 text-white rounded-lg hover:bg-green-700 ${loadingButton ? 'opacity-50 cursor-not-allowed' : ''}`}
                            >
                                {loadingButton ? 'Submitting...' : 'Submit & Next Step Upload Document'}
                            </button>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

export default CodingTestQuestion;
